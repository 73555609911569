import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";
import _ from 'lodash';

// components
import Layout from "../../components/layout";
import BackButton from "../../components/common/BackButton";
import EnvironmentalData from "../../components/material/tabs/EnvironmentalData";

// modals
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import HelpModal from "../../components/modals/HelpModal";
import { MaterialEnvironmentalDataHelp } from "../../utils/helpContent";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { materialAction } from "../../redux/actions/materialActions";

// props
import { RootState } from "../../redux/store";

// helpers
import { checkDuplicateFiles } from "../../utils/materialHelper";
import { findFile, getUpdatedFiles, setTitleNumberInput } from "../../utils/common";
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";

// initialData
const initialData: any = {
    material_name: "",
    vendor_name: "",
    lot_number: "",
    metric1: "",
    metric2: "",
    metric3: "",
    metric4: "",
    attachments: [],
};

const Environmental = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();

    // auth
    const { authState } = useOktaAuth();
    const auth: any = authState ? authState?.accessToken : '';

    // Show data in input fields to update
    const material = useSelector((state: RootState) => state.material.item);

    // states
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openHelp, setOpenHelp] = useState<boolean>(false);
    const [materialData, setMaterialData] = useState<any>();

    const methods = useForm({ defaultValues: initialData, mode: 'all' });

    useEffect(() => {
        methods.watch();
    });

    useEffect(() => {
        // set default title on number input fields.
        setTitleNumberInput();
        if (material) {
            setMaterialData(material);
            let resetFields: any = {};
            resetFields.material_name = material?.material_name ?? "";
            resetFields.vendor_name = material?.vendor_name ?? "";
            resetFields.lot_number = material?.lot_number ?? "";

            if (material?.environment) {
                resetFields.metric1 = material?.environment?.metric1?.toString() ?? "";
                resetFields.metric2 = material?.environment?.metric2?.toString() ?? "";
                resetFields.metric3 = material?.environment?.metric3?.toString() ?? "";
                resetFields.metric4 = material?.environment?.metric4?.toString() ?? "";
                if (material?.environment?.attachments?.length) {
                    let newAttachments: any = [];
                    material?.environment?.attachments?.forEach((attachment: any) => {
                        let e: any = {};
                        if (attachment.category === 'XX Report') {
                            e.category = attachment.category;
                            e.error = false;
                            e.isUpload = true;
                            e.file = {
                                name: attachment.display_name,
                                type: attachment.mime_type,
                            };
                            newAttachments.push(e);
                        }
                    });
                    resetFields.attachments = newAttachments;
                } else {
                    resetFields.attachments = [];
                }
            } else {
                resetFields.metric1 = methods.control._formValues.metric1;
                resetFields.metric2 = methods.control._formValues.metric2;
                resetFields.metric3 = methods.control._formValues.metric3;
                resetFields.metric4 = methods.control._formValues.metric4;
                resetFields.attachments = methods.control._formValues.attachments;
            }
            methods.reset(resetFields);
        }
    }, [material]);

    // method trigger on select file
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files?.length) {
            let displayNames = material?.environment?.attachments ?? [];
            displayNames = displayNames?.map((attachment: any) => attachment.display_name);
            const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                if (displayNames.includes(e?.target?.files?.[key].name)) {
                    return true;
                }
            });
            if (isDuplicate) {
                dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }
            methods.setValue('attachments', [
                ...methods.control._formValues.attachments,
                ...Object.keys(e?.target?.files ?? []).map(
                    (key: any) => {
                        const fileSize = e?.target?.files?.[key]?.size ?? 0;
                        return {
                            category: 'XX Report',
                            file: e?.target?.files?.[key],
                            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                        }
                    })], { shouldDirty: true, shouldTouch: true });
        }
    }

    // method trigger to remove selected file
    const removeFile = (index: number, name: string) => {
        methods.setValue('attachments', methods.control._formValues.attachments.filter((attachment: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true });
    }

    const uploadFile = async (file: any) => {
        const newAttachment = await findFile(methods.control._formValues.attachments, file);
        if (newAttachment) {
            const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
            const uploadResponse = await fetch(file.signedUrl,
                {
                    method: 'PUT',
                    body: blob,
                })
            if (uploadResponse.ok) return true;
            return false;
        }
        return false;
    }

    const uploadFileAPI = async (attachmentList: any[]) => {
        if (attachmentList?.length) {
            const res = await FileService.create("/files/upload", {
                id: materialData.id,
                file_for: 'Material',
                files: attachmentList.map(
                    (attachment: any) => ({
                        category: attachment?.category,
                        key: attachment.file?.name,
                        mimeType: attachment?.file?.type
                    })),
            });

            if (res?.status === 200) {
                const filesResult = res?.data;
                return Promise.all(filesResult.body.files.map((file: any) => uploadFile(file)))
                    .then(async (res) => {
                        // update material object
                        filesResult.body.files.map((file: any) => delete file.signedUrl);

                        const payload = {
                            ...materialData,
                            id: materialData.id,
                            uid: auth?.claims?.uid,
                            environment: {
                                metric1: methods.control._formValues.metric1,
                                metric2: methods.control._formValues.metric2,
                                metric3: methods.control._formValues.metric3,
                                metric4: methods.control._formValues.metric4,
                                attachments: [
                                    ...material?.environment?.attachments ?? [],
                                    ...filesResult.body.files,
                                ]
                            },
                        }

                        payload.environment.attachments = _.uniqBy(payload.environment.attachments, function (e: any) {
                            return e.display_name;
                        });

                        payload.environment.attachments = await getUpdatedFiles(payload.environment.attachments, methods.control._formValues.attachments, !0);

                        // material update request
                        const materialResponse = await MaterialService.update(payload);

                        // setLoading(false); // disable loading
                        if (materialResponse?.status === 200) {
                            dispatch(materialAction(materialResponse?.data?.body));
                            return true;
                        }
                        return false;
                    })
                    .catch(() => {
                        return false;
                    })
            }
            return false;
        }
        return false;
    }

    const apiRequest = async (newAttachments: any[]) => {
        if (newAttachments?.length) {
            return await uploadFileAPI(newAttachments);
        }

        const payload = {
            ...materialData,
            id: materialData.id,
            uid: auth?.claims?.uid,
            environment: {
                metric1: methods.control._formValues.metric1,
                metric2: methods.control._formValues.metric2,
                metric3: methods.control._formValues.metric3,
                metric4: methods.control._formValues.metric4,
                attachments: await getUpdatedFiles(material?.environment?.attachments, methods.control._formValues.attachments, !1),
            }
        };

        // material update request
        const res = await MaterialService.update(payload);

        // setLoading(false); // disable loading
        if (res?.status === 200) {
            dispatch(materialAction(res?.data?.body));
            return true;
        }
        return false;
    }

    const validateForm = async () => {
        const newAttachments = methods.control._formValues?.attachments.filter((attachment: any) => !attachment.isUpload);
        if (newAttachments?.length) {
            const invalidateFiles = methods.control._formValues?.attachments.find((attachment: any) => attachment.error);
            // Check invalid files.
            if (invalidateFiles) {
                dispatch(alertOpenAction('Max file size exceed. Please try again with valid files.', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }
            // Check duplicate files in same category.
            const isDuplicate = await (await checkDuplicateFiles(methods.control._formValues?.attachments))?.map((item: any) => item.isDuplicate).includes(true);
            if (isDuplicate) {
                dispatch(alertOpenAction('Please remove duplicate files.', 'error'));
                setTimeout(() => dispatch(alertCloseAction()));
                return;
            }

            return true;
        }
        return true;
    }

    // Submit Form
    const onSubmit: SubmitHandler<any> = async (data: any) => {
        if (await validateForm()) {
            const newAttachments = methods.control._formValues.attachments.filter((attachment: any) => !attachment.isUpload);

            setLoading(true); // enable Loading
            const apiResponse = await apiRequest(newAttachments);
            setLoading(false); // disble Loading

            if (apiResponse) {
                dispatch(alertOpenAction(`Environment Data ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
            } else {
                dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
            }
            setTimeout(() => dispatch(alertCloseAction()));
        }
    };

    const saveMaterial = async () => {
        if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
            setOpen(false);
            if (await validateForm()) {
                const newAttachments = methods.control._formValues.attachments.filter((attachment: any) => !attachment.isUpload);

                setLoading(true); // enable Loading
                const apiResponse = await apiRequest(newAttachments);
                setLoading(false); // disble Loading

                if (apiResponse) {
                    dispatch(alertOpenAction(`Environment Data ${params?.id ? 'updated' : 'added'} successfully.`, 'success'));
                    params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
                } else {
                    dispatch(alertOpenAction('Oops! something went wrong.', 'error'));
                }
                setTimeout(() => dispatch(alertCloseAction()));
            }
        } else {
            setOpen(false);
            dispatch(alertOpenAction('No data updated to save.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    const dontSave = () => {
        setOpen(false);
        params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
    }

    return (
        <>
            <Layout title="Solid Properties - Environmental-Data">
                <CancelConfirmModal open={open} setOpen={setOpen} saveMaterial={saveMaterial} dontSave={dontSave} />
                <HelpModal
                    open={openHelp}
                    setOpen={setOpenHelp}
                    title={MaterialEnvironmentalDataHelp.title}
                    content={MaterialEnvironmentalDataHelp.content}
                />
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="sec-info control-head">
                            <div className="back">
                                <BackButton onSubmit={saveMaterial} formState={methods.formState} material={material} />
                            </div>
                            <div className="head">
                                <h1 className="head-lg">Environmental Data</h1>
                            </div>
                            <div className="right">
                                <button title="Save" type="submit" disabled={loading || !(methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) ? true : false} className={`icon-btn ${loading ? "disabled  " : ""}`}>
                                    <i className="fa-regular fa-floppy-disk" />
                                </button>
                                <Link className="icon-btn alter" title="Close" to="#" onClick={() => {
                                    if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                                        setOpen(true);
                                        return;
                                    }
                                    params?.id ? history.push(`/materials/${params?.id}/update`) : history.push(`/materials/new/`);
                                }}>
                                    <i className="fa-solid fa-xmark" />
                                </Link>
                                <Link to="#" title="Help" className="icon-btn alter" onClick={() => { setOpenHelp(true) }}>
                                    <i className="fa-solid fa-question" />
                                </Link>
                            </div>
                        </div>

                        <div className="theme-card">
                            <div className="body">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <label className="ip-wrap" htmlFor="material_name" title="Material Name">
        <span className="form-label">Material Name</span>
                                                <Controller
                                                    name="material_name"
                                                    control={methods.control}
                                                    render={({ field }) => <input {...field} disabled={true} className="theme-ip" />}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <label className="ip-wrap" htmlFor="vendor_name" title="Vendor">
        <span className="form-label">Vendor</span>
                                                <div className="input-wrap">
                                                    <Controller
                                                        name="vendor_name"
                                                        control={methods.control}
                                                        render={({ field }) => <input {...field} disabled={true} className="theme-ip" />}
                                                    />
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="form-group">
                                            <label className="ip-wrap" htmlFor="lot_number" title="Lot Number">
        <span className="form-label">Lot Number</span>
                                                <Controller
                                                    name="lot_number"
                                                    control={methods.control}
                                                    render={({ field }) => <input {...field} disabled={true} className="theme-ip" />}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* divider */}
                                <div className="divider"></div>

                                <EnvironmentalData
                                    attachments={methods.control._formValues.attachments}
                                    handleFileChange={handleFileChange}
                                    removeFile={removeFile}
                                />
                            </div>
                            {loading ? <div className="theme-loader show fixed">
                                <div className="loader-outer">
                                    <div className="loader"></div>
                                </div>
                            </div> : ''}
                        </div>
                    </form>
                </FormProvider>
            </Layout>
        </>
    );
};

export default Environmental;