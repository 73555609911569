import React from "react";
import Plot from "react-plotly.js";

export default function ContourGraph({ plot }: { plot: any }) {
  return <Plot data={plot.data}
               layout={{
                 ...plot.layout,
                 height: 600,
                 width: 600,
               }} />;
}

