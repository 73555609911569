import { Controller } from 'react-hook-form';
import { NUMBER_INPUT_MAXLENGTH } from '../../utils';
import { limitDigits } from '../../utils/common';

const TextController = (props: any) => {
  return (
    <div className={`${props?.col ?? 'col-lg-3'} col-md-6`}>
      <div className="form-group" >
        <label key={props?.name} className={`ip-wrap ${props.required && !props.readOnly ? 'required' : ''}`} htmlFor="tag_number">
          <span className="form-label" title={props?.title}>
            {props.label}
          </span>
          {!props.readOnly ? (
            <>
              <Controller
                name={props.name}
                control={props.methods?.control}
                render={({ field }) =>
                  <input
                    {...field}
                    className="theme-ip"
                    type={props?.type ?? 'text'}
                    maxLength={35}
                    defaultValue={props.value.toString() ?? ""}
                    onInput={(e: any) => props.type == "number" ? limitDigits(e, NUMBER_INPUT_MAXLENGTH) : ''}
                  // {...props.methods?.register(props?.name, { valueAsNumber: props.type == "number" ? true : false })}
                  />
                }
              />
            </>
          ) : (
            <p className="theme-ip pt-3 disabled">
              {props?.value.toString() ?? ''}
            </p>
          )}

          {props?.errors ?
            props.errors[props?.name] && <p className="error">{props.errors[props?.name]?.message}</p>
            : ''
          }
        </label>
      </div>
    </div>
  )
}

export default TextController