import { Controller, useForm, useFormContext } from "react-hook-form";

// helpers
import { NUMBER_INPUT_MAXLENGTH } from "../../../../../utils";
import { limitDigits } from "../../../../../utils/common";

const Densities = (props: any) => {
    const { control } = useForm<any>({ defaultValues: props.initialData });
    const methods = useFormContext();

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="bulk_densities" title="Bulk Densities (g/ml)">
                            <span className="form-label">Bulk Densities (g/ml)</span>
                            {!props.readable ? (
                                <Controller
                                    name="densities.bulk_densities"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.densities?.bulk_densities??""}
                                    render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.bulk_densities}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="tapped_density" title="Tapped Density (g/ml)">
                            <span className="form-label">Tapped Density (g/ml)</span>
                            {!props.readable ? (
                                <Controller
                                    name="densities.tapped_density"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.densities?.tapped_density??""}
                                    render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.tapped_density}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="eln_number" title="ELN Number">
                            <span className="form-label">ELN Number</span>
                            {!props.readable ? (
                                <Controller
                                    name="densities.eln_number"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.densities?.eln_number??""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label
                            className="ip-wrap"
                            htmlFor="regulatory_filing"
                            title="Regulatory Filing"
                        >
                            <span className="form-label">
                                Regulatory Filing
                            </span>
                            <div className="input-wrap select-outer">
                                {!props.readable ? (
                                <Controller
                                    name="densities.regulatory_filing"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.densities?.regulatory_filing??""}
                                    render={({ field }) =>
                                        <select className="theme-ip" {...field}>
                                            <option value="">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>}
                                />
                                ) : (
                                    <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing}</p>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Densities;