import { useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";

const SEM = (props: any) => {
    const methods = useFormContext();

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    {!props.readable ? (
                        <MultiFileUpload
                            title="SEM Images"
                            name="semAttachments"
                            attachments={methods.control._formValues.sem.attachments}
                            handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let displayNames = props.material?.solid?.sem?.attachments ?? [];
                                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                                        return true;
                                    }
                                });
                                if (isDuplicate) {
                                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                                    setTimeout(() => props.dispatch(alertCloseAction()));
                                    return;
                                }
                                methods.setValue('sem.attachments', [
                                    ...(methods.getValues('sem.attachments') || []),
                                    ...Object.keys(e?.target?.files ?? []).map(
                                        (key: any) => {
                                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                                            return {
                                                category: 'SEM',
                                                file: e?.target?.files?.[key],
                                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                                            }
                                        })
                                ], { shouldDirty: true, shouldTouch: true });
                            }}
                            removeFile={(index: number) => {
                                methods.setValue('sem.attachments', (methods.getValues('sem.attachments') || []).filter((attachment: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true })
                            }}
                        />
                    ) : (
                        <MultiFileUpload
                            title="SEM Images"
                            name="semFiles"
                            readable={props.readable}
                            attachments={props.attachments}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default SEM;