// components
import MultiFileUpload from '../common/MultiFileUpload';
import CustomLoader from '../common/CustomLoader';

const UploadSolubilityData = (props: any) => {
    return (
        <div className='row'>
            { props.loading && <CustomLoader />}
            <div className="col-lg-6">
                <div className="row">
                    <div className="col-lg-6">
                            <MultiFileUpload
                                title="Unchained Lab Data"
                                name="unchainedLab"
                                showDownload={true}
                                attachments={props?.attachments?.unchainedLab ?? []}
                                handleFileChange={props.handleFileChange}
                                removeFile={props.removeFile}
                                downloadFile={props.downloadFile}
                                hasPermission={props.hasPermission}
                            />
                        </div>
                        <div className="col-lg-6">
                            <MultiFileUpload
                                title="HPLC Data"
                                name="hplc"
                                showDownload={true}
                                attachments={props?.attachments?.hplc ?? []}
                                handleFileChange={props.handleFileChange}
                                removeFile={props.removeFile}
                                downloadFile={props.downloadFile}
                                hasPermission={props.hasPermission}
                            />
                        </div>
                        <div className="col-lg-6">
                            <MultiFileUpload
                                title="Crystal 16 Data"
                                name="crystal16"
                                showDownload={true}
                                attachments={props?.attachments?.crystal16 ?? []}
                                handleFileChange={props.handleFileChange}
                                removeFile={props.removeFile}
                                downloadFile={props.downloadFile}
                                hasPermission={props.hasPermission}
                            />
                        </div>
                        <div className="col-lg-6">
                            <MultiFileUpload
                                title="Crystalline Data"
                                name="crystalline"
                                showDownload={true}
                                attachments={props?.attachments?.crystalline ?? []}
                                handleFileChange={props.handleFileChange}
                                removeFile={props.removeFile}
                                downloadFile={props.downloadFile}
                                hasPermission={props.hasPermission}
                            />
                        </div>
                        <div className="col-lg-6">
                            <MultiFileUpload
                                title="Gravimetry Method"
                                name="gravimetryMethod"
                                showDownload={true}
                                attachments={props?.attachments?.gravimetryMethod ?? []}
                                handleFileChange={props.handleFileChange}
                                removeFile={props.removeFile}
                                downloadFile={props.downloadFile}
                                hasPermission={props.hasPermission}
                            />
                        </div>
                </div>
            </div>
            <div className="col-lg-6 divider-left">
                <div className="row">
                    <div className="col-lg-6">
                        <MultiFileUpload
                            title="Solubility Curve 1"
                            name="solubilityCurve1"
                            showDownload={true}
                            attachments={props?.attachments?.solubilityCurve1 ?? []}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                            hasPermission={props.hasPermission}
                        />
                    </div>
                    <div className="col-lg-6">
                        <MultiFileUpload
                            title="Solubility Curve 2"
                            name="solubilityCurve2"
                            showDownload={true}
                            attachments={props?.attachments?.solubilityCurve2 ?? []}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                            hasPermission={props.hasPermission}
                        />
                    </div>
                    <div className="col-lg-6">
                        <MultiFileUpload
                            title="Solubility Curve 3"
                            name="solubilityCurve3"
                            showDownload={true}
                            attachments={props?.attachments?.solubilityCurve3 ?? []}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                            hasPermission={props.hasPermission}
                        />
                    </div>
                    <div className="col-lg-6">
                        <MultiFileUpload
                            title="Solubility Curve 4"
                            name="solubilityCurve4"
                            showDownload={true}
                            attachments={props?.attachments?.solubilityCurve4 ?? []}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                            hasPermission={props.hasPermission}
                        />
                    </div>
                    <div className="col-lg-6">
                        <MultiFileUpload
                            title="Solubility Curve 5"
                            name="solubilityCurve5"
                            showDownload={true}
                            attachments={props?.attachments?.solubilityCurve5 ?? []}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                            hasPermission={props.hasPermission}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadSolubilityData;