import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import BackConfirmModal from "../modals/BackFormModal";

// redux actions
import { alertOpenAction, alertCloseAction } from "../../redux/actions";

const SolubilityButton = (props: any) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [openBack, setOpenBack] = useState(false);

    const saveMaterialBack = async () => {
        setOpenBack(false);
        if (props.material) {
            await props.onSubmit(props.material);
            history.push('/materials/material-solubility');
        } else {
            //   setOpen(false)
            dispatch(alertOpenAction('No data updated to save.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        }
    }

    const handleBack = () => {
        history.push(`/materials/material-solubility`);
    }

    return (
        <>
            <BackConfirmModal
                open={openBack}
                setOpen={setOpenBack}
                saveMaterial={saveMaterialBack}
                dontSave={handleBack}
                dontSaveLabel={`Don't Save & Go`}
                saveLabel={`Save & Go`} 
            />
            <div className="col-lg-3 col-md-6 ">
                <div className="form-group margin-top-25">
                    <button title="Solubility Module" type="button" className="theme-btn md-2" onClick={() => {
                        if (props.formState.isDirty || Object.keys(props.formState.dirtyFields).length) {
                            setOpenBack(true);
                            return;
                        }
                        handleBack();
                    }}>
                        Solubility Module
                    </button>
                </div>
            </div>

        </>
    )
}

export default SolubilityButton;