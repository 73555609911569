// general
import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// components
import Layout from "../../components/layout";
import {
  Vessel,
  Reactor,
  Filtration,
  Mixer,
  Evaporator,
  GeneralEquipment,
  Distillation
} from "../../components/equipment/tabs";

// modals
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import HelpModal from "../../components/modals/HelpModal";
import BackConfirmModal from "../../components/modals/BackFormModal";

// context
import { useUser } from "../../components/context/UserContext";

// services
import FileService from "../../services/fileService";
import EquipmentService from "../../services/equipmentService";

// redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// redux actions
import { alertCloseAction, alertOpenAction } from "../../redux/actions";
import { addEquipmentAction, equipmentAction } from "../../redux/actions/equipmentActions";

// interface
import { EquipmentData } from "../../types/equipmentTypes";
import { ResponseProps } from "../../types";

// helpers
import { getUpdatedFiles } from "../../utils/common";
import { NewEquipmentHelp } from "../../utils/equipmentHelpContent";
import { getAttachments, initialEquipmentData } from "../../utils/equipmentHelper";
import { checkDuplicateFiles } from "../../utils/materialHelper";
import { apiRoutes } from "../../utils/apiRoutes";

import {
  NEW_EQUIPMENT_SUCCESS_MESSAGE,
  UPDATE_EQUIPMENT_SUCCESS_MESSAGE,
  ERROR_MESSAGE,
} from "../../utils/constant";

const schema = yup.object().shape({
  equipment_type: yup.string().required("Please select equipment type."),
  equipment_owner: yup.string().required("Please select equipment owner."),
  tag_number: yup.string().required("Please enter tag number."),
});

const EquipmentNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authState } = useOktaAuth();

  // user context
  const { user } = useUser();

  const auth: any = authState ? authState?.accessToken : '';

  const equipment = useSelector((state: RootState) => state?.equipment);

  const methods = useForm<EquipmentData>({
    defaultValues: initialEquipmentData(equipment?.item),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    methods.watch();
  })

  useEffect(() => {
    methods.reset(methods.control._formValues);
  }, [equipment?.item]);

  //state variables
  const [loading, setLoading] = useState<Boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openBack, setOpenBack] = useState<boolean>(false);
  const parseFile = (attachment: any) => {
    return {
      category: attachment.category,
      file: {
        name: attachment.display_name,
        type: attachment.mime_type,
      },
      error: false,
      isUpload: true
    }
  }

  // To show warning when trying to exit form
  useEffect(() => {
    window.addEventListener('beforeunload', refreshData);
    return () => {
      window.removeEventListener('beforeunload', refreshData);
    };
  }, []);

  const refreshData = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
  }, [methods.control._formValues?.equipment_type]);

  useEffect(() => {
    if (equipment && equipment?.item) {
      const filesByCategory: any = {
        drawing: [],
        manufacturer_manual: [],
        sop: [],
        service_log: [],
      };

      Object.keys(equipment?.item?.[equipment?.item?.equipment_type]?.attachments ?? {}).forEach((key: string) => {
        switch (key) {
          case 'drawing':
            filesByCategory.drawing = [
              ...filesByCategory.drawing,
              ...equipment?.item?.[equipment?.item?.equipment_type]?.attachments[key]?.map((attachment: any) => parseFile(attachment)),
            ];
            break;
          case 'manufacturer_manual':
            filesByCategory.manufacturer_manual = [
              ...filesByCategory.manufacturer_manual,
              ...equipment?.item?.[equipment?.item?.equipment_type]?.attachments[key]?.map((attachment: any) => parseFile(attachment)),
            ];
            break;
          case 'sop':
            filesByCategory.sop = [
              ...filesByCategory.sop,
              ...equipment?.item?.[equipment?.item?.equipment_type]?.attachments[key]?.map((attachment: any) => parseFile(attachment)),
            ];
            break;
          case 'service_log':
            filesByCategory.service_log = [
              ...filesByCategory.service_log,
              ...equipment?.item?.[equipment?.item?.equipment_type]?.attachments[key]?.map((attachment: any) => parseFile(attachment)),
            ];
            break;
          default:
          // default
        }
      });
      methods.setValue(`${equipment?.item?.equipment_type}.attachments` as any, filesByCategory);
    }
  }, [equipment?.item]);

  const findFile = (items: any[], file: any) => {
    return items.find(
      (item) => item.category === file.category && item.file?.name.toLocaleLowerCase().replaceAll(' ', '-') === file.display_name);
  };

  const uploadFile = async (file: any) => {
    const attachments: any = getAttachments(equipment?.item ?? {}, methods.control._formValues);
    let newAttachment = findFile(attachments, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: 'PUT',
          body: blob,
        })
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  };

  const uploadFileAPI = async (data: any, attachmentList: any[], id?: string) => {
    const result: ResponseProps = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    // file upload request
    const filesResponse = await FileService.create("/files/upload", {
      id: equipment?.item?.id ?? id,
      file_for: 'Equipment',
      files: attachmentList,
    });

    if (filesResponse?.status === 200) {
      const filesResult = filesResponse?.data?.body;
      return Promise.all(filesResult.files.map((file: any) => uploadFile(file)))
        .then(async (res) => {
          // update material object
          filesResult.files.map((file: any) => delete file.signedUrl);

          const r = filesResult?.files?.reduce((e: any, t: any) => {
            switch (t.category) {
              case 'Drawing':
                e.drawing = [...e?.drawing, t];
                break;
              case 'Manufacturer Manual':
                e.manufacturer_manual = [...e?.manufacturer_manual, t];
                break;
              case 'SOP':
                e.sop = [...e?.sop, t];
                break;
              case 'Service Log':
                e.service_log = [...e?.service_log, t];
                break;
            }
            return e;
          }, {
            drawing: [],
            manufacturer_manual: [],
            sop: [],
            service_log: [],
          });

          // filesResult.body.files = await getUpdatedFiles(filesResult?.body?.files ?? [], attachmentList, !0);

          const payload = {
            ...data,
            id: equipment?.item?.id ?? id,
            uid: `${auth?.claims?.uid}`,
          }

          payload[methods.control._formValues?.equipment_type] = {
            ...methods.control._formValues[methods.control._formValues?.equipment_type],
            attachments: {
              drawing: await getUpdatedFiles(
                [...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.drawing ?? [], ...r.drawing],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.drawing ?? [], !0),
              manufacturer_manual: await getUpdatedFiles(
                [...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.manufacturer_manual ?? [], ...r.manufacturer_manual],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.manufacturer_manual ?? [], !0),
              sop: await getUpdatedFiles([...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.sop ?? [], ...r.sop],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.sop ?? [], !0),
              service_log: await getUpdatedFiles([...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.service_log ?? [], ...r.service_log],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.service_log ?? [], !0),
            },
          }

          // payload[methods.control._formValues.equipment_type] = {
          //   ...methods.control._formValues[methods.control._formValues.equipment_type],
          //   attachments: filesResult.body.files
          // };

          const equipmentResponse = await EquipmentService.update(payload);
          if (equipmentResponse?.data?.code === 200) {
            dispatch(equipmentAction(equipmentResponse?.data?.body));
            result.data = equipmentResponse?.data?.body;
            result.status = "success";
            result.message = equipmentResponse?.data?.message??UPDATE_EQUIPMENT_SUCCESS_MESSAGE;
            return result;
          }
          result.code = equipmentResponse?.data?.code;
          result.status = "error";
          result.message = equipmentResponse?.data?.message??ERROR_MESSAGE;
          return result;
        })
        .catch(err => {
          result.code = 400;
          result.status = "error";
          result.message = err.message??ERROR_MESSAGE;
          return result;
        });
    }
    result.code = filesResponse?.data?.code;
    result.status = "error";
    result.message = filesResponse?.data?.message??ERROR_MESSAGE;
    return result;
  }

  const apiRequest = async () => {
    const result: ResponseProps = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    const attachments =
      methods.control._formValues[methods.control._formValues.equipment_type]?.attachments ?
        Object.values(methods.control._formValues[methods.control._formValues.equipment_type].attachments).reduce((e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        }, []) : [];

    let attachmentList = attachments.filter((attachment: any) => !attachment.isUpload).map(
      (attachment) => ({
        category: attachment?.category,
        key: attachment.file?.name,
        mimeType: attachment?.file?.type
      }));

    const {
      equipment_type,
      equipment_owner,
      tag_number,
      location,
      brand,
      manufacturer_model_number,
      equipment_of_construction,
      application_and_scale,
    } = methods.control._formValues;

    let payload: any = {
      uid: auth?.claims?.uid,
      equipment_type,
      equipment_owner,
      tag_number,
      location,
      brand,
      manufacturer_model_number,
      equipment_of_construction,
      application_and_scale,
    }

    payload[methods.control._formValues.equipment_type] = methods.control._formValues[methods.control._formValues.equipment_type];
    // setLoading(true); // enable loading

    const res = await EquipmentService.create(apiRoutes.EQUIPMENTS, payload);
    if (res?.data?.code === 200) {
      // setLoading(false);
      if (attachmentList?.length) {
        // upload files API
        return {
          ...result,
          ...await uploadFileAPI(methods.control._formValues, attachmentList, res?.data?.body?.id),
          ...{ message: res?.data?.message??NEW_EQUIPMENT_SUCCESS_MESSAGE }
        };
      }
      dispatch(addEquipmentAction(res?.data?.body));
      dispatch(equipmentAction(res?.data?.body));
      result.data = res?.data?.body;
      result.status = "success";
      result.message = res?.data?.message??NEW_EQUIPMENT_SUCCESS_MESSAGE;
      return result;
    }
    result.code = res?.data?.code;
    result.status = "error";
    result.message = res?.data?.message??ERROR_MESSAGE;
    return result;
  }

  const validateForm = async () => {
    //To check data in required fields on save&close button click
    if (
      !(
        methods.control._formValues?.equipment_type?.length &&
        methods.control._formValues?.equipment_owner?.length &&
        methods.control._formValues?.tag_number?.length
      )
    ) {
      dispatch(alertOpenAction('Please fill required fields first.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return
    }

    const attachments =
      methods.control._formValues[methods.control._formValues.equipment_type]?.attachments ?
        Object.values(methods.control._formValues[methods.control._formValues.equipment_type].attachments).reduce((e: any[], t: any) => {
          e = [...e, ...t];
          return e;
        }, []) : [];

    const invalidateFiles = attachments.find((attachment: any) => attachment.error);
    if (invalidateFiles) { // Check invalid file
      dispatch(alertOpenAction('Max file size exceed. Please try again with valid files.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    const isDuplicate = await (await checkDuplicateFiles(attachments))?.map((item: any) => item.isDuplicate).includes(true);
    if (isDuplicate) {
      dispatch(alertOpenAction('Please remove duplicate files.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    return true;
  }

  ///UPDATEEEEEEEEEE

  const uploadUpdatedFile = async (attachmentList: any[], file: any) => {
    let newAttachment = await findFile(attachmentList, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], {
        type: newAttachment?.file?.type,
      });
      const uploadResponse = await fetch(file.signedUrl, {
        method: "PUT",
        body: blob,
      });
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  };

  const uploadUpdatedFileAPI = async (
    data: any,
    attachmentList: any[],
    id?: string
  ) => {
    const result: ResponseProps = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    // setLoading(true); // enable loading
    const filesResponse = await FileService.create("/files/upload", {
      id,
      file_for: 'Equipment',
      files: attachmentList.map(
        (attachment: any) => ({
          category: attachment?.category,
          key: attachment.file?.name,
          mimeType: attachment?.file?.type,
        })),
    });
    // setLoading(false); // disable loading
    if (filesResponse?.status === 200) {
      const filesResult = filesResponse?.data?.body;
      // setLoading(true); // enable loading
      return Promise.all(filesResult.files.map((file: any) => uploadUpdatedFile(attachmentList, file)))
        .then(async (res) => {
          // update equipment object
          filesResult.files.map((file: any) => delete file.signedUrl);

          if (equipment?.item?.attachments) {
            filesResult.files = [
              ...equipment?.item?.attachments,
              ...filesResult.files,
            ];
          }

          const r = filesResult?.files?.reduce((e: any, t: any) => {
            switch (t.category) {
              case 'Drawing':
                e.drawing = [...e?.drawing, t];
                break;
              case 'Manufacturer Manual':
                e.manufacturer_manual = [...e?.manufacturer_manual, t];
                break;
              case 'SOP':
                e.sop = [...e?.sop, t];
                break;
              case 'Service Log':
                e.service_log = [...e?.service_log, t];
                break;
            }
            return e;
          }, {
            drawing: [],
            manufacturer_manual: [],
            sop: [],
            service_log: [],
          });

          filesResult.files = await getUpdatedFiles(filesResult?.files ?? [], attachmentList, !0);

          const payload = {
            ...data,
            id: equipment?.item?.id ?? id,
            uid: `${auth?.claims?.uid}`,
          }

          payload[methods.control._formValues?.equipment_type] = {
            ...methods.control._formValues[methods.control._formValues?.equipment_type],
            attachments: {
              drawing: await getUpdatedFiles(
                [...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.drawing ?? [], ...r.drawing],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.drawing ?? [], !0),
              manufacturer_manual: await getUpdatedFiles(
                [...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.manufacturer_manual ?? [], ...r.manufacturer_manual],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.manufacturer_manual ?? [], !0),
              sop: await getUpdatedFiles([...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.sop ?? [], ...r.sop],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.sop ?? [], !0),
              service_log: await getUpdatedFiles([...equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.service_log ?? [], ...r.service_log],
                methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.service_log ?? [], !0),
            },
          }

          const equipmentResponse = await EquipmentService.update(payload);
          // setLoading(false); // disable loading
          if (equipmentResponse?.data?.code === 200) {
            dispatch(equipmentAction(equipmentResponse?.data?.body));
            result.data = equipmentResponse?.data?.body;
            result.status = "success";
            result.message = equipmentResponse?.data?.message??UPDATE_EQUIPMENT_SUCCESS_MESSAGE;
            return result;
          }
          result.code = equipmentResponse?.data?.code;
          result.status = "error";
          result.message = equipmentResponse?.data?.message??ERROR_MESSAGE;
          return result;
        })
        .catch((err) => {
          result.code = 400;
          result.status = "error";
          result.message = err.message??ERROR_MESSAGE;
          return result;
        });
    }
    result.code = filesResponse?.data?.code;
    result.status = "error";
    result.message = filesResponse?.data?.message??ERROR_MESSAGE;
    return result;
  };

  const updateApiRequest = async (equipmentId: any) => {
    const result: ResponseProps = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    const attachments = Object.keys(methods.control._formValues[methods.control._formValues?.equipment_type]?.attachments).reduce((e: any, t: any) => {
      e = [...e, ...methods.control._formValues[methods.control._formValues?.equipment_type].attachments[t]];
      return e;
    }, []);
    // attachments list
    let attachmentList = attachments.filter((attachment: any) => !attachment.isUpload);
    // setLoading(true);

    if (attachmentList?.length) {
      // upload files API
      return {
        ...result,
        ...await uploadUpdatedFileAPI(methods.control._formValues, attachmentList, equipment?.item?.id),
      };
    }

    const {
      equipment_type,
      equipment_owner,
      tag_number,
      location,
      brand,
      manufacturer_model_number,
      material_of_construction,
      application_and_scale,
    } = methods.control._formValues;

    let payload: any = {
      uid: auth?.claims?.uid,
      id: equipmentId ?? '',
      equipment_type,
      equipment_owner,
      tag_number,
      location,
      brand,
      manufacturer_model_number,
      material_of_construction,
      application_and_scale,
    }

    payload[methods.control._formValues?.equipment_type] = {
      ...methods.control._formValues[methods.control._formValues?.equipment_type],
      attachments: {
        drawing: await getUpdatedFiles(
          equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.drawing ?? [],
          methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.drawing ?? [], !1),
        manufacturer_manual: await getUpdatedFiles(
          equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.manufacturer_manual ?? [],
          methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.manufacturer_manual ?? [], !1),
        sop: await getUpdatedFiles(equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.sop ?? [],
          methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.sop ?? [], !1),
        service_log: await getUpdatedFiles(equipment?.item?.[equipment?.item?.equipment_type]?.attachments?.service_log ?? [],
          methods.control._formValues?.[methods.control._formValues?.equipment_type]?.attachments?.service_log ?? [], !1),
      },
    }

    const res = await EquipmentService.update(payload);
    if (res?.data?.code === 200) {
      dispatch(equipmentAction(res?.data?.body));
      result.data = res?.data?.body;
      result.status = "success";
      result.message = res?.data?.message??NEW_EQUIPMENT_SUCCESS_MESSAGE;
      return result;
    }
    result.code = res?.data?.code;
    result.status = "error";
    result.message = res?.data?.message??ERROR_MESSAGE;
    return result;
  }

  const onSubmit: SubmitHandler<any> = async () => {
    if (await validateForm()) {
      setLoading(true); // enable loading
      if (equipment && equipment?.item) {
        const apiResponse = await updateApiRequest(equipment?.item?.id);
        setLoading(false); // disable loading
        if (apiResponse.code === 200) {
          dispatch(alertOpenAction(apiResponse.message, 'success'));
        } else {
          dispatch(alertOpenAction(apiResponse.message, 'error'));
        }
      } else {
        const apiResponse = await apiRequest();
        setLoading(false); // disable loading
        if (apiResponse.code === 200) {
          dispatch(alertOpenAction(apiResponse.message, 'success'));
        } else {
          dispatch(alertOpenAction(apiResponse.message, 'error'));
        }
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  // Cancel button functions
  const saveEquipment = async () => {
    if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
      setOpen(false);
      setOpenBack(false);
      if (await validateForm()) {
        setLoading(true); // enable loading
        if (equipment && equipment?.item) {
          const apiResponse = await updateApiRequest(equipment?.item?.id);
          setLoading(false); // disable loading
          if (apiResponse.code === 200) {
            dispatch(alertOpenAction(apiResponse.message, 'success'));
            history.push('/equipments');
          } else {
            dispatch(alertOpenAction(apiResponse.message, 'error'));
          }
        } else {
          const apiResponse = await apiRequest();
          setLoading(false); // disable loading
          if (apiResponse.code === 200) {
            dispatch(alertOpenAction(apiResponse.message, 'success'));
            history.push('/equipments');
          } else {
            dispatch(alertOpenAction(apiResponse.message, 'error'));
          }
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpen(false);
      dispatch(alertOpenAction('Please add data first.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  const dontSave = () => {
    setOpen(false);
    history.push('/equipments');
  }

  return (
    <Layout title="Equipment New">
      <CancelConfirmModal
        open={open}
        setOpen={setOpen}
        saveMaterial={saveEquipment}
        dontSave={dontSave}
      />
      <BackConfirmModal
        open={openBack}
        setOpen={setOpenBack}
        saveMaterial={saveEquipment}
        dontSave={dontSave}
      />
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={NewEquipmentHelp.title}
        content={NewEquipmentHelp.content} />

      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="sec-info control-head">
            <div className="back">
              <Link to='#' title="Back" className="icon-btn alter" onClick={() => {
                if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                  setOpenBack(true);
                  return;
                }
                history.push(`/equipments`);
              }}>
                <i className="fa-solid fa-arrow-left" />
              </Link>
            </div>
            <div className="head">
              <h1 className="head-lg">Add New Equipment</h1>
            </div>
            <div className="right">
              <button type="submit" title="Save" disabled={loading ? true : false} className={`icon-btn ${loading ? "disabled  " : ""}`}>
                <i className="fa-regular fa-floppy-disk"></i>
              </button>

              <Link className="icon-btn alter" title="Close" to="#" onClick={() => {
                if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                  setOpen(true);
                  return;
                }
                history.push(`/equipments`);
              }}>
                <i className="fa-solid fa-xmark" />
              </Link>
              <Link to="#" title="Help" className="icon-btn alter" onClick={() => { setOpenHelp(true) }}>
                <i className="fa-solid fa-question" />
              </Link>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="theme-card">
                <div className="body">
                  <GeneralEquipment readOnly={equipment?.item ? true : false} user={user} />
                  {methods?.control?._formValues?.equipment_type == "vessel" ?
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">Vessel</div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Vessel
                          attachments={methods.control._formValues?.vessel?.attachments}
                        />
                      </TabPanel>
                    </Tabs>
                    : ''}
                  {methods?.control?._formValues?.equipment_type == "reactor" ?
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">
                              Reactor/Crystallizer
                            </div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Reactor
                          attachments={methods.control._formValues?.reactor?.attachments}
                        />
                      </TabPanel>
                    </Tabs>
                    : ''}
                  {methods?.control?._formValues?.equipment_type == "distillation" ?
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">
                              Distillation
                            </div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Distillation
                          attachments={methods.control._formValues?.distillation?.attachments}
                        />
                      </TabPanel>
                    </Tabs>
                    : ''}
                  {methods?.control?._formValues?.equipment_type == "filtration" ?
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">
                              Filtration
                            </div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Filtration
                          attachments={methods.control._formValues?.filtration.attachments}
                        />
                      </TabPanel>
                    </Tabs>
                    : ''}
                  {methods?.control?._formValues?.equipment_type == "mixer" ?
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">
                              Mixer
                            </div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Mixer
                          attachments={methods.control._formValues?.mixer?.attachments}
                        />
                      </TabPanel>
                    </Tabs>
                    : ''}
                  {methods?.control?._formValues?.equipment_type == "evaporator" ?
                    <Tabs>
                      <div className="admin-tabs mx-card mb-6">
                        <TabList className="inner mb-3">
                          <Tab>
                            <div className="tab-link">
                              Evaporator
                            </div>
                          </Tab>
                        </TabList>
                      </div>
                      <TabPanel>
                        <Evaporator
                          attachments={methods.control._formValues?.evaporator?.attachments}
                        />
                      </TabPanel>
                    </Tabs>
                    : ''}
                </div>

              </div>
            </div>
          </div>
          {loading ?
            <div className="theme-loader show fixed">
              <div className="loader-outer">
                <div className="loader"></div>
              </div>
            </div> : ''}
        </form>
      </FormProvider>
    </Layout >
  );
};

export default EquipmentNew;
