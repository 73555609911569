import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

// helpers
import {
    disabledFields,
    gasGeneralFields,
    liquidGeneralFields,
    materialGeneralFields,
    solidGeneralFields,
} from "../../utils/materialHelper";

const solidLabels = (key: string) => {
    let newKey: string = key;
    if (key == "drug_info") {
        newKey = "Drug Information";
    }
    if (key == "flow_energy") {
        newKey = "Flow Energy";
    }
    if (key == "shear_cell") {
        newKey = "Shear Cell";
    }
    if (key == "thermal_tga") {
        newKey = "Thermal (TGA)";
    }
    if (key == "thermal_dsc") {
        newKey = "Thermal (DSC)";
    }
    return <label>{(newKey).charAt(0).toUpperCase()}{(newKey).slice(1)}</label>
}

const ExportFields = (props: any) => {
    const methods = useFormContext();
    useEffect(() => {
        methods.watch();
    })

    const parseFields = (fields: any, value: boolean) => {
        return Object.keys(fields).reduce((e: any, t: any) => {
            e[t] = value;
            return e;
        }, {})
    }

    const parseSolidFields: any = (value: boolean) =>
        Object.keys(solidGeneralFields).length && Object.keys(solidGeneralFields).reduce(
            (p: any[], q: any) => {
                p[q] = solidGeneralFields[q].reduce((e: any, t: any) => {
                    e[t.value] = value;
                    return e;
                }, {})
                return p;
            }, []);

    const handleChange = (value: boolean) => {
        const { gas, liquid, material } = methods.control._formValues;
        if (value) {
            switch (props.type) {
                case "gas": {
                    methods.setValue('gas', parseFields(gas, value));
                } break;
                case "liquid": {
                    methods.setValue('liquid', parseFields(liquid, value));
                } break;
                case "solid": {
                    methods.setValue('solid', parseSolidFields(value));
                } break;
                case "material": {
                    methods.setValue('material', parseFields(material, value));
                } break;
                default: {
                    console.log("No tab selected");
                }
            }
        } else {
            switch (props.type) {
                case "gas":
                    methods.setValue('gas', parseFields(gas, value));
                    break;
                case "liquid":
                    methods.setValue('liquid', parseFields(liquid, value));
                    break;
                case "solid": {
                    methods.setValue('solid', parseSolidFields(value));
                } break;
                case "material":
                    methods.setValue('material', parseFields(material, value));
                    break;
                default:
                    console.log("No tab selected")
            }
        }
    }

    return (
        <div className="filter-sidebar list-tab">
            <div className="filter-block">
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="custom-checkbox">
                                <Controller
                                    name={`${props.type}.selectAll`}
                                    control={methods.control}
                                    render={() => (
                                        <input className="ip" type="checkbox"
                                            {...methods.register(`${props.type}.selectAll`)}
                                            onChange={(e: any) => { handleChange(e.target.checked) }} />
                                    )}
                                />
                                {/* <input className="ip" onChange={(e: any) => { handleChange(e.target.checked) }} type="checkbox" /> */}
                                <span className="check"></span>
                                <span className="txt">Select All</span>
                            </label>
                        </div>
                    </div>
                    <div className="row xxs-row">
                        {props?.type == 'material' ?
                            <>
                                {disabledFields?.map((item: any) => (
                                    <div className="col-md-6" key={item.value}>
                                        {item?.value && item.name ?
                                            <label className="custom-checkbox disabled" title={item.name}>
                                                <Controller
                                                    name={`material.${item.value}`}
                                                    control={methods.control}
                                                    render={() => (
                                                        <input className="ip" type="checkbox" disabled checked
                                                            {...methods.register(`material.${item.value}`)} />
                                                    )}
                                                />
                                                <span className="check"></span>
                                                <span className="txt">{item.name}</span>
                                            </label> : ''}
                                    </div>
                                ))}

                                {/* <div className="col-md-6">
                                        <label className="custom-checkbox">
                                            <Controller
                                                name={`material.vendor_name`}
                                                control={methods.control}
                                                defaultValue={true}
                                                render={() => (
                                                    <input className="ip" type="checkbox" 
                                                        {...methods.register(`material.vendor_name`)} />
                                                )}
                                            />
                                            <span className="check"></span>
                                            <span className="txt">Vendor</span>
                                        </label>
                                </div> */}
                                {materialGeneralFields?.map((item: any) => (
                                    <div className="col-md-6" key={item.value}>
                                        {item?.value && item.name ?
                                            <label className="custom-checkbox" title={item.name}>
                                                <Controller
                                                    name={`material.${item.value}`}
                                                    control={methods.control}
                                                    render={() => (
                                                        <input className="ip" type="checkbox"
                                                            {...methods.register(`material.${item.value}`)} />
                                                    )}
                                                />
                                                <span className="check"></span>
                                                <span className="txt">{item.name}</span>
                                            </label> : ''}
                                    </div>
                                ))}
                            </>
                            : ''}
                        {props?.type == 'solid' ?
                            <>
                                {
                                    Object.keys(solidGeneralFields).length && Object.keys(solidGeneralFields).map(
                                        (key: string) =>
                                        (
                                            <>
                                                <div className="col-12 mb-2">
                                                    {solidLabels(key)}
                                                </div>
                                                {solidGeneralFields?.[key]?.map((item: any) => (
                                                    <div className="col-md-6">
                                                        <label className="custom-checkbox">
                                                            <Controller
                                                                name={`solid.${[key]}.${item.value}`}
                                                                control={methods.control}
                                                                defaultValue={false}
                                                                render={() => (
                                                                    <input className="ip" type="checkbox"
                                                                        {...methods.register(`solid.${[key]}.${item.value}`)} />
                                                                )}
                                                            />
                                                            <span className="check"></span>
                                                            <span className="txt">{item.name}</span>
                                                        </label>
                                                    </div>
                                                ))}
                                            </>
                                        )
                                    )
                                }

                            </>
                            : ''}
                        {props?.type == 'liquid' ?
                            <>
                                {liquidGeneralFields?.map((item: any) => (
                                    <div className="col-md-6"key={item.value}>
                                        {item?.value && item.name ?
                                            <label className="custom-checkbox">
                                                <Controller
                                                    name={`liquid.${item.value}`}
                                                    control={methods.control}
                                                    render={() => (
                                                        <input className="ip" type="checkbox"
                                                            {...methods.register(`liquid.${item.value}`)} />
                                                    )}
                                                />
                                                <span className="check"></span>
                                                <span className="txt">{item.name}</span>
                                            </label> : ''}
                                    </div>
                                ))}
                            </>
                            : ''}
                        {props?.type == 'gas' ?
                            <>

                                {gasGeneralFields.map((item: any) => (
                                    <div className="col-md-6" key={item.value}>
                                        <label className="custom-checkbox">
                                            <Controller
                                                name={`gas.${item.value}`}
                                                control={methods.control}
                                                render={() => (
                                                    <input className="ip" type="checkbox"
                                                        {...methods.register(`gas.${item.value}`)} />
                                                )}
                                            />
                                            <span className="check"></span>
                                            <span className="txt">{item.name}</span>
                                        </label>
                                    </div>
                                ))}
                            </>
                            : ''}
                    </div>
                </div>
            </div>
        </div >
        // </form>
    );
};

export default ExportFields;