import { useEffect } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../common/MultiFileUpload";
import SolubilityButton from "../../common/SolubilityButton";
import { NUMBER_INPUT_MAXLENGTH } from "../../../utils";
import { limitDigits } from "../../../utils/common";

const SolubilityInformation = (props: any) => {
    const methods = useFormContext();
    const { control, reset } = useForm({ defaultValues: props?.initialData ?? {} });

    useEffect(() => {
        methods?.watch();
    });

    // useEffect(() => {
    //     reset(props.initialData);
    // }, [props?.initialData?.material_name]);

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="main_solvent" title="Main Solvent">
                            <span className="form-label">Main Solvent</span>
                            {!props.readable ? (
                                <Controller
                                    name="solubility.main_solvent"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.solubility?.main_solvent ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.main_solvent}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="solubility_in_main_solvent" title="Solubility in the main Solvent(mg/ml)">
                            <span className="form-label">Solubility in the main Solvent(mg/ml)</span>
                            {!props.readable ? (
                                <Controller
                                    name="solubility.solubility_in_main_solvent"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.solubility?.solubility_in_main_solvent ?? ""}
                                    render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.solubility_in_main_solvent}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="main_solvent_temperature" title="Main Solvent Temperature(C)">
                            <span className="form-label">Main Solvent Temperature(C)</span>
                            {!props.readable ? (
                                <Controller
                                    name="solubility.main_solvent_temperature"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.solubility?.main_solvent_temperature ?? ""}
                                    render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.main_solvent_temperature}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="main_solvent_reference" title="Main Solvent Reference">
                            <span className="form-label">Main Solvent Reference</span>
                            {!props.readable ? (
                                <Controller
                                    name="solubility.main_solvent_reference"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.solubility?.second_solvent_reference ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.main_solvent_reference}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="second_solvent" title="Second Solvent">
                            <span className="form-label">Second Solvent</span>
                            {!props.readable ? (
                                <Controller
                                    name="solubility.second_solvent"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.solubility?.second_solvent ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.second_solvent}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="solubility_in_second_solvent" title="Solubility in the Second Solvent(mg/ml)">
                            <span className="form-label">Solubility in the Second Solvent(mg/ml)</span>
                            {!props.readable ? (
                                <Controller
                                    name="solubility.solubility_in_second_solvent"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.solubility?.solubility_in_second_solvent ?? ""}
                                    render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control._formValues.solubility_in_second_solvent}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="second_solvent_temperature" title="Second Solvent Temperature(C)">
                            <span className="form-label">Second Solvent Temperature(C)</span>
                            {!props.readable ? (
                                <Controller
                                    name="solubility.second_solvent_temperature"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.solubility?.second_solvent_temperature ?? ""}
                                    render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.second_solvent_temperature}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="second_solvent_reference" title="Second Solvent Reference">
                            <span className="form-label">Second Solvent Reference</span>
                            {!props.readable ? (
                                <Controller
                                    name="solubility.second_solvent_reference"
                                    control={methods?.control ?? control}
                                    defaultValue={methods.control._formValues?.solubility?.second_solvent_reference ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.second_solvent_reference}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="Solubility Curve"
                        name="solubilityCurve"
                        readable={props.readable}
                        attachments={props.attachments}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                        downloadFile={props.downloadFile}
                    />
                </div>
                {props.readable ? '' :
                    <SolubilityButton onSubmit={props.onSubmit} formState={methods?.formState} material={props.material} />
                }
            </div>
        </>
    );
};

export default SolubilityInformation;