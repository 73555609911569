import React, { useEffect, useState } from "react";
import UnchainedForm from "./Form";
import SolubilityTable from "./SolubilityTable";
import ImageGrid from "./ImageGrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { getMaterialChains } from "../../../../services/materialChains";
import CustomLoader from "../../../../components/common/CustomLoader";
import "./index.css";

export default function UnchainedLabs({ setSubmitFn }: { setSubmitFn: any }) {
  const [unchainedData, setUnchainedData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const material = useSelector((state: RootState) => state.material);
  useEffect(() => {
    const item = material?.item;
    if (item) {
      setUnchainedData(null);
      setLoading(true);
      getMaterialChains({
        uid: item.uid,
        vendor_name: item.vendor_name,
        material_name: item.material_name,
        lot_number: item.lot_number,
        material_id: item.material_id,
      }).then(res => {
          if (res.data.body.saved && res.data.body.saved === "yes")
            setUnchainedData(res.data.body);
          setLoading(false);
        },
      );
    }
  }, [material?.item]);
  return <div>
    {loading ? <CustomLoader /> :
      <div className="grid grid-cols-12 gap-12">
        <div className="col-span-6 xl:col-span-3 lg-screen-col-2">
          <UnchainedForm setUnchainedData={setUnchainedData} unchainedData={unchainedData} setSubmitFn={setSubmitFn} />
        </div>
        <div className="col-span-6 xl:col-span-4 lg-screen-col-3">
          {
            unchainedData &&
            <SolubilityTable unchainedData={unchainedData} />
          }
        </div>
        <div className="col-span-12 xl:col-span-5 lg-screen-col-7">
          <div className="flex gap-4 flex-col min-h-full">
            {
              unchainedData &&
              <ImageGrid unchainedData={unchainedData} />
            }
            <div className="mt-12 pl-12">
              <div>
                  <span className="text-2xl font-bold text-right flex-1"
                        style={{ maxWidth: "25%" }}>Material Name : </span>
                <span
                  className="text-2xl font-bold flex-1">{material?.item?.material_name}</span>
              </div>
            </div>
            <div className="pl-12">
              <span className="text-2xl font-bold text-right"
                    style={{ maxWidth: "25%" }}>Project Name : </span>
              <span
                className="text-2xl font-bold">{material?.item?.project_name}</span>
            </div>
          </div>
        </div>
      </div>
    }
  </div>;
}