import { useEffect } from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";

// helpers
import MultiFileUpload from "../../common/MultiFileUpload";
import { NUMBER_INPUT_MAXLENGTH } from "../../../utils";
import { limitDigits } from "../../../utils/common";

const EnvironmentalData = (props: any) => {
    const methods = useFormContext();
    // form
    const { control } = useForm<any>({ defaultValues: props.initialData });

    useEffect(() => {
        props.readable ? '' : methods?.watch();
    });

    // useEffect(() => {
    //     reset(props.initialData);
    // }, [props?.initialData?.material_name]);

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="metric1">
                            <span className="form-label">Metric 1</span>
                            {!props.readable ? (
                                <Controller
                                    name="metric1"
                                    control={methods?.control ?? control}
                                    render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} className="theme-ip" />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.metric1}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="metric2">
                            <span className="form-label">Metric 2</span>
                            {!props.readable ? (
                                <Controller
                                    name="metric2"
                                    control={methods?.control ?? control}
                                    render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} className="theme-ip" />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.metric2}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="metric3">
                            <span className="form-label">Metric 3</span>
                            {!props.readable ? (
                                <Controller
                                    name="metric3"
                                    control={methods?.control ?? control}
                                    render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} className="theme-ip" />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.metric3}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="metric4">
                            <span className="form-label">Metric 4</span>
                            {!props.readable ? (
                                <Controller
                                    name="metric4"
                                    control={methods?.control ?? control}
                                    render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field} className="theme-ip" />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.metric4}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                        title="XX Report"
                        name="xxReport"
                        readable={props.readable}
                        attachments={props.attachments}
                        handleFileChange={props.handleFileChange}
                        removeFile={props.removeFile}
                    />
                </div>
                {props.readable ? '' :
                    <div className="col-lg-3 col-md-6 ">
                        <div className="form-group margin-top-25">
                            <button title="Green Dashboard" type="button" className="theme-btn md-2">Green Dashboard</button>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default EnvironmentalData;