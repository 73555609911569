import React, { useEffect, useMemo, useState } from "react";
import FileService from "../../../../services/fileService";
import { saveFile } from "../../../../hooks/useFileDownload";
import ImageNotAvailable from "../../../../assets/Image_not_available.png";

const array24 = Array.from(Array(24).keys());

function useGridData(unchainedData: any) {
  const [gridData, setGridData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
      async function load() {

        setLoading(true);
        const csvData = unchainedData.csv_data;
        const gData: any[] = csvData.map((data: any, index: any) => ({
          index,
          solvent: data.Solvent,
        }));

        for (let i = 0; i < gData.length; i += 4) {
          const resArr = await Promise.allSettled(
            gData.slice(i, i + 4).map((d: any, index: number) => FileService.create("/files/download", {
              file: unchainedData[`file__${i + index + 1}`].file_name,
            })),
          );
          resArr.forEach(({ value: res }: any, index: number) => {
            gData[i + index].image = unchainedData[`file__${i + index + 1}`].file_name != "NA" ? res.data.body : ImageNotAvailable;
            gData[i + index].showDonload = unchainedData[`file__${i + index + 1}`].file_name != "NA";
          });

        }
        setGridData(gData);
        setLoading(false);
      }

      load();
    }, [unchainedData],
  );
  return { gridData, loading };
}

function GridElement({ gridElement }: { gridElement: any }) {
  const [fileDownloading, setFileDownloading] = useState(false);
  return <div
    className={`border-r p-1 border-b border-gray-400 flex justify-between items-center flex-col ${fileDownloading && "cursor-wait"}`}>
    <img
      alt="Material Image" src={gridElement.image} />
    <div className="flex justify-between">
              <span>{gridElement.solvent}
                </span>
    </div>
  </div>;
}

function Grid({ gridData }: { gridData: any }) {
  return <>
    {
      gridData.map((grid: any) => <GridElement gridElement={grid} key={grid.key} />)
    }
  </>;
}

export default function ImageGrid({ unchainedData }: { unchainedData: any }) {
  const { gridData, loading } = useGridData(unchainedData);
  return <>
    {
      loading ?
        <div className="w-full h-full flex flex-1 justify-center items-center">
          <div className="loader"></div>
        </div>
        :
        <div className="grid grid-cols-6 border border-gray-400">
          <Grid gridData={gridData} />
        </div>
    }
  </>;
}