import { Controller, useForm, useFormContext } from "react-hook-form";

// components
import MultiFileUpload from "../../../../common/MultiFileUpload";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../../../../utils";
import { limitDigits } from "../../../../../utils/common";
import { alertCloseAction, alertOpenAction } from "../../../../../redux/actions";

const Aeration = (props: any) => {
    const { control } = useForm<any>({ defaultValues: props.initialData });
    const methods = useFormContext();

    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="aeration_ratio" title="Aeration Ratio (4mm/s)">
                            <span className="form-label">Aeration Ratio (4mm/s)</span>
                            {!props.readable ? (
                                <Controller
                                    name="aeration.aeration_ratio"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.aeration?.aeration_ratio ?? ""}
                                    render={({ field }) =>
                                        <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.aeration_ratio}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="aeration_energy" title="Aeration Energy (mJ)">
                            <span className="form-label">Aeration Energy (mJ)</span>
                            {!props.readable ? (
                                <Controller
                                    name="aeration.aeration_energy"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.aeration?.aeration_energy ?? ""}
                                    render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.aeration_energy}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="eln_number" title="ELN Number">
                            <span className="form-label">ELN Number</span>
                            {!props.readable ? (
                                <Controller
                                    name="aeration.eln_number"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.aeration?.eln_number ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.eln_number}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label className="ip-wrap" htmlFor="method_name" title="Method Name/#">
                            <span className="form-label">Method Name/#</span>
                            {!props.readable ? (
                                <Controller
                                    name="aeration.method_name"
                                    control={methods.control}
                                    defaultValue={methods.control._formValues?.aeration?.method_name ?? ""}
                                    render={({ field }) => <input className="theme-ip" {...field} />}
                                />
                            ) : (
                                <p className="theme-ip pt-3 disabled">{control?._formValues?.method_name}</p>
                            )}
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                        <label
                            className="ip-wrap"
                            htmlFor="regulatory_filing"
                            title="Regulatory Filing"
                        >
                            <span className="form-label">
                                Regulatory Filing
                            </span>
                            <div className="input-wrap select-outer">
                                {!props.readable ? (
                                    <Controller
                                        name="aeration.regulatory_filing"
                                        control={methods.control}
                                        defaultValue={methods.control._formValues?.aeration?.regulatory_filing ?? ""}
                                        render={({ field }) =>
                                            <select className="theme-ip" {...field}>
                                                <option value="">Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>}
                                    />
                                ) : (
                                    <p className="theme-ip pt-3 disabled">{control?._formValues?.regulatory_filing}</p>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    {!props.readable ? (
                        <MultiFileUpload
                            title="SOP File, Instrument Information"
                            name="aerationAttachments"
                            attachments={methods.control._formValues.aeration.attachments}
                            handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let displayNames = props.material?.solid?.aeration?.attachments ?? [];
                                displayNames = displayNames?.map((attachment: any) => attachment.display_name);
                                const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
                                    if (displayNames.includes(e?.target?.files?.[key].name)) {
                                        return true;
                                    }
                                });
                                if (isDuplicate) {
                                    props.dispatch(alertOpenAction('Same file already uploaded.', 'error'));
                                    setTimeout(() => props.dispatch(alertCloseAction()));
                                    return;
                                }
                                methods.setValue('aeration.attachments', [
                                    ...(methods.getValues('aeration.attachments') || []),
                                    ...Object.keys(e?.target?.files ?? []).map(
                                        (key: any) => {
                                            const fileSize = e?.target?.files?.[key]?.size ?? 0;
                                            return {
                                                category: 'Aeration',
                                                file: e?.target?.files?.[key],
                                                error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                                            }
                                        })
                                ], { shouldDirty: true, shouldTouch: true });
                            }}
                            removeFile={(index: number, name: string) => {
                                methods.setValue('aeration.attachments', (methods.getValues('aeration.attachments') || []).filter((attachment: any, i: number) => i !== index), { shouldDirty: true, shouldTouch: true })
                            }}
                        />
                    ) : (
                        <MultiFileUpload
                            title="SOP File, Instrument Information"
                            name="aerationFiles"
                            readable={props.readable}
                            attachments={props.attachments}
                            handleFileChange={props.handleFileChange}
                            removeFile={props.removeFile}
                            downloadFile={props.downloadFile}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Aeration;