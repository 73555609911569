import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

// services
import MaterialService from '../../services/materialService';

// redux actions
import { materialAction } from '../../redux/actions/materialActions';
import { alertCloseAction, alertOpenAction } from '../../redux/actions';

// props
import { RootState } from '../../redux/store';
import { VendorProps } from "../../types/materialsTypes";

// helpers
import { vendorNames } from '../../utils/materialHelper';
import { sortItemsByKey } from '../../utils/common';
import { apiRoutes } from '../../utils/apiRoutes';

const HeaderRow = (props: any) => {
    const dispatch = useDispatch();

    // auth
    const { authState } = useOktaAuth();
    const auth: any = authState ? authState?.accessToken : '';
    const material = useSelector((state: RootState) => state.material.item);

    const initialData = {
        loading: false,
        vendor_name: material?.vendor_name ?? '',
        material_name: material?.material_name ?? '',
        lot_number: material?.lot_number ?? '',
    }

    // states
    const [loading, setLoading] = useState<boolean>(false);
    const [materialNameOptions, setMaterialNameOptions] = useState<any[]>([]);
    const [lotNumberOptions, setLotNumberOptions] = useState<any[]>([]);
    const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);
    const { control, watch, setValue } = useForm({ defaultValues: initialData });
    const { materialDeleted } = props;

    //Get onChange values for search
    const watchedFields = {
        loading,
        vendor_name: watch("vendor_name"),
        material_name: watch("material_name"),
        lot_number: watch("lot_number"),
    }
    let vendorName = watch("vendor_name");

    useEffect(() => {
        if (props.user) {
            const { userType, vendors } = props.user;
            if (userType.includes('external')) {
                // okta dashboard vendors for external users.
                setVendors(sortItemsByKey([
                    { label: 'Generic', value: 'Generic' },
                    ...vendors.map((vendor: VendorProps) => ({ label: vendor, value: vendor })),
                ], 'label'));
            }
        }
    }, [props.user]);

    useEffect(() => {
        setValue("vendor_name", '');
        setValue("material_name", '');
        setValue("lot_number", '');
        setMaterialNameOptions([]);
        setLotNumberOptions([]);
        dispatch(materialAction(null));
    }, [materialDeleted]);

    //Get material names and lot numbers dropdown options
    useEffect(() => {
        if (watchedFields?.vendor_name && watchedFields?.material_name) {
            if (material?.vendor_name !== watchedFields?.vendor_name || material?.material_name !== watchedFields?.material_name) {
                watchedFields?.lot_number ? setLoading(true) : '';
                setValue("lot_number", '');
                dispatch(materialAction(null));
            }
            getLotNumbers()
        }
    }, [watchedFields?.vendor_name, watchedFields?.material_name]);

    useEffect(() => {
        if (vendorName) {
            if (material?.vendor_name !== watchedFields?.vendor_name) {
                watchedFields?.material_name ? setLoading(true) : '';
                setValue("material_name", '');
                dispatch(materialAction(null));
            }
            getMaterialNames();
        }
    }, [watchedFields?.vendor_name]);

    useEffect(() => {
        props.headerData(watchedFields);
    }, [watchedFields?.vendor_name, watchedFields?.lot_number, watchedFields?.material_name, watchedFields?.loading]);

    // method to get material names.
    const getMaterialNames = async () => {
        const payload = {
            vendor_name: watchedFields?.vendor_name,
            uid: `${auth?.claims?.uid}`,
        };
        setLoading(true); // enable loading
        const res = await MaterialService.create(apiRoutes.LIST_MATERIAL_NAMES_MATERIAL, payload);
        setLoading(false); // disable loading
        if (res?.data?.code == 200) {
            if (res?.data?.body?.length) {
                setMaterialNameOptions(sortItemsByKey(res?.data?.body??[], 'material_name'));
            }
        } else {
            dispatch(
                alertOpenAction("No related materials present.", "error")
            );
            setTimeout(() => dispatch(alertCloseAction()));
        };
    };

    // method to get material lot numbers
    const getLotNumbers = async () => {
        const payload = {
            uid: `${auth?.claims?.uid}`,
            vendor_name: watchedFields?.vendor_name,
            material_name: watchedFields?.material_name,
        };
        setLoading(true); // enable loading
        const res = await MaterialService.create(apiRoutes.LIST_LOT_NUMBERS_MATERIAL, payload);
        setLoading(false); // disable loading
        if (res?.data?.code === 200) {
            setLotNumberOptions(sortItemsByKey(res?.data?.body??[], 'lot_number'));
        } else {
            dispatch(alertOpenAction('Unable to load the related Lot Numbers.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        };
    };

    return (
        <div className="row">
            <div className="col-lg-4 col-md-6">
                <div className="form-group">
                    <label className="ip-wrap" htmlFor="vendor">
                        <span className="form-label">Vendor</span>
                        <div className="input-wrap select-outer">
                            <Controller
                                name="vendor_name"
                                control={control}
                                render={({ field }) => (
                                    <select className="theme-ip" {...field}>
                                        <option value="" disabled selected>Select</option>
                                        {material ?
                                            <option value={material?.vendor_name} selected>{material?.vendor_name}</option>
                                            : ''}
                                        {vendors?.filter((vendor: VendorProps, index: number) => !vendor.label.includes(material?.vendor_name)).map((vendor: any, index: number) => (
                                            <option key={index} value={vendor?.value}>
                                                {vendor.label}
                                            </option>
                                        ))}
                                    </select>
                                )}
                            />
                        </div>
                    </label>
                </div>
            </div>

            <div className="col-lg-4 col-md-6">
                <div className="form-group">
                    <label className="ip-wrap" htmlFor="material_name">
                        <span className="form-label">Material Name</span>
                        <div className="input-wrap select-outer">
                            <Controller
                                name="material_name"
                                control={control}
                                defaultValue={material?.material_name ?? ""}
                                render={({ field }) =>
                                    <select className="theme-ip" disabled={watchedFields?.vendor_name ? false : true} {...field}>
                                        <option value="" disabled selected={material ? false : true}>Select</option>
                                        {materialNameOptions?.length && materialNameOptions?.map((name: any, index: number) =>
                                            <option key={index} selected={material?.material_name === name?.material_name} value={name.material_name}>
                                                {name?.material_name}
                                            </option>
                                        )}
                                    </select>
                                }
                            />
                        </div>
                    </label>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="form-group ">
                    <label className="ip-wrap" htmlFor="lot_number">
                        <span className="form-label">Lot Number</span>
                        <div className="input-wrap select-outer">
                            <Controller
                                name="lot_number"
                                control={control}
                                render={({ field }) =>
                                    <select className="theme-ip" disabled={watchedFields?.vendor_name && watchedFields?.material_name ? false : true} {...field}>
                                        <option value="" disabled selected={material ? false : true}>Select</option>
                                        {lotNumberOptions?.length && lotNumberOptions?.map((name: any, index: number) => (
                                            <option key={index} selected={material?.lot_number === name?.lot_number} value={name.lot_number}>
                                                {name?.lot_number}
                                            </option>
                                        ))}
                                    </select>}
                            />
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default HeaderRow