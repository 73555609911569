
import { useEffect, useState} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// components
import MultiFileUpload from '../common/MultiFileUpload';
import CustomLoader from '../common/CustomLoader';

// charts
import LineChart from '../charts/LineChart';

// redux action
import { alertOpenAction, alertCloseAction } from '../../redux/actions';

// helpers
import {NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH} from '../../utils';
import {limitDigits} from '../../utils/common';

import LaurasModels from './LaurasModels';

const TakedaModels = (props: any) => {
    const dispatch = useDispatch();
    const methods = useFormContext();
    const {headerFilters, model3Saved} = props;
   
  return (
    <div className="row">
      {props.loading && <CustomLoader/>}
      <div className="col-md-4">
        <div className="">
          <div className="col-md-12">
            <div className="form-group">
              <label className="ip-wrap" htmlFor="model">
                <span className="form-label">Model</span>
                <div className="input-wrap select-outer">
                  <Controller
                    name="model"
                    control={methods.control}
                    defaultValue={methods.control._formValues.model}
                    render={({field}) =>
                      <select {...field} className="theme-ip">
                        <option value="" disabled={true}>Select</option>
                        <option value="Model 1">Model 1</option>
                        <option value="Model 2">Model 2</option>
                        <option value="Model 3">Model 3 (Laura's Model)</option>
                      </select>
                    }
                  />
                </div>
              </label>
            </div>
          </div>
          {methods.control._formValues.model === 'Model 3' && <div className="col-md-12">
            <>
              <div className="form-group">
                <MultiFileUpload
                  title='Upload (Camera PNG file)'
                  name="upload"
                  acceptFormat="image/png"
                  attachments={methods.control._formValues.attachments.model3}
                  handleFileChange={(e) => {
                    const invalidFormat = Array.from(e?.target?.files ?? [])?.find((file: any) => file.type !== 'image/png');
                    if (invalidFormat) {
                      dispatch(alertOpenAction('Invalid file format. Please select .png extension file.', 'error'));
                      setTimeout(() => dispatch(alertCloseAction()));
                      return;
                    }
                    methods.setValue('attachments.model3', [...Object.keys(e?.target?.files ?? []).map(
                      (key: any) => {
                        const fileSize = e?.target?.files?.[key]?.size ?? 0;
                        return {
                          category: 'Solubility Model3',
                          file: e?.target?.files?.[key],
                          error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
                        }
                      })], {shouldDirty: true, shouldTouch: true})
                  }}
                  removeFile={(index: number) => {
                    methods.setValue('attachments', methods.control._formValues.attachments.model3.filter((attachment: any, i: number) => i !== index))
                    methods.setValue('solubility_value', '');
                    methods.setValue('turbidity_measured', '');
                  }}
                  multiple={false}
                />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="ip-wrap" htmlFor="turbidity_measured">
                    <span className="form-label">Turbudity Measured</span>
                    <Controller
                      name="turbidity_measured"
                      control={methods.control}
                      render={({field}) => <input type="number"
                                                  onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} {...field}
                                                  className="theme-ip"/>}
                    />
                  </label>
                </div>
              </div>
            </>
          </div>}
          <div className="col-md-12">
            <div className="form-group">
              <label className="ip-wrap" htmlFor="solvent">
                <span className="form-label">Solvent</span>
                <div className="input-wrap select-outer">
                  <Controller
                    name="solvent"
                    control={methods.control}
                    defaultValue={methods.control._formValues.solvent}
                    render={({field}) =>
                      <select {...field} className="theme-ip">
                        <option value="" disabled={true}>Select</option>
                        <option value="1-Propanol">1-Propanol</option>
                        <option value="2-Butanol">2-Butanol</option>
                        <option value="2-Me-THF">2-Me-THF</option>
                        <option value="Acetic Acid">Acetic Acid</option>
                        <option value="Acetone">Acetone</option>
                        <option value="Acetonitrile">Acetonitrile</option>
                        <option value="Anisole">Anisole</option>
                        <option value="Butanone">Butanone</option>
                        <option value="Buty Acetate">Buty Acetate</option>
                        <option value="Cyclohexane">Cyclohexane</option>
                        <option value="Cyclopentyl Methyl Ether">Cyclopentyl Methyl Ether</option>
                        <option value="DCM">DCM</option>
                        <option value="Dimethyl Carbonate">Dimethyl Carbonate</option>
                        <option value="Dimethylacetamide">Dimethylacetamide</option>
                        <option value="DME">DME</option>
                        <option value="DMF">DMF</option>
                        <option value="DMSO">DMSO</option>
                        <option value="Ethanol">Ethanol</option>
                        <option value="Ethyl Acetate">Ethyl Acetate</option>
                        <option value="EtOAc:EtOH (10:90)">EtOAc:EtOH (10:90)</option>
                        <option value="Heptane">Heptane</option>
                        <option value="Isoamyl Acetate">Isoamyl Acetate</option>
                        <option value="IsoButanol">IsoButanol</option>
                        <option value="IsoOctane">IsoOctane</option>
                        <option value="IsoPropyl Acetate">IsoPropyl Acetate</option>
                        <option value="IsoPropyl Alcohol">IsoPropyl Alcohol</option>
                        <option value="Methanol">Methanol</option>
                        <option value="Methyl Isobutyl Ketone">Methyl Isobutyl Ketone</option>
                        <option value="MTBE">MTBE</option>
                        <option value="NMP">NMP</option>
                        <option value="Pyridine">Pyridine</option>
                        <option value="t-Amyl Methyl Ether">t-Amyl Methyl Ether</option>
                        <option value="t-Butyl Alcohol">t-Butyl Alcohol</option>
                        <option value="THF">THF</option>
                        <option value="Toluene">Toluene</option>
                        <option value="TPGS-750-M (2 wt% in water)">TPGS-750-M (2 wt% in water)</option>
                        <option value="Water">Water</option>
                        <option value="Water:Acetone (10:90)">Water:Acetone (10:90)</option>
                        <option value="Water:Acetonitrile (10:90)">Water:Acetonitrile (10:90)</option>
                        <option value="Xylenes">Xylenes</option>
                      </select>
                    }
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="ip-wrap" htmlFor="temperature">
                <span className="form-label">Temperature(C)</span>
                <div className="flex mt-3">
                  <div className="slidecontainer w-full">
                    <Controller
                      name="temperature"
                      control={methods.control}
                      render={({field}) => (
                        <input
                          type="range"
                          min="1"
                          max="100"
                          className="slider w-full block"
                          id="solubility"
                          value={methods.control._formValues.temperature}
                          onChange={(e) => field.onChange(parseInt(e.target.value))}
                        />
                      )}
                    />
                  </div>
                  <div className="value ml-1 w-10 text-right">
                    {methods.control._formValues.temperature}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="ip-wrap" htmlFor="solubility_value">
                <span className="form-label">Solubility(mg/ml)</span>
                <Controller
                  name="solubility_value"
                  control={methods.control}
                  render={({field}) =>
                    <input className="theme-ip" {...field} readOnly={true}/>}
                />
              </label>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group custome-fs">
              <button title="Calculate Solubility" onClick={props.handleSolubility}
                      className="theme-btn mb-6 w-full btn-md">Calculate Solubility
              </button>
            </div>
          </div>
        </div>
      </div>

      {methods.control._formValues.model == 'Model 3' ? 
      (<div className="col-md-8">
        <LaurasModels headerFilters={headerFilters} model3Saved={model3Saved}></LaurasModels>
      </div>) : (
        <div className="col-md-8">
          <div className="row">
            <div className="col-xl-4">
              <div className="form-group">
                <label className="custom-checkbox">
                  <Controller
                    name="visual"
                    control={methods.control}
                    defaultValue={false}
                    render={({field}) => (
                      <input className="ip" type="checkbox"
                             onChange={(e) => field.onChange(e.target.checked)}
                             checked={field.value}/>
                    )}
                  />
                  <span className="check"></span>
                  <span className="txt form-label">Visual</span>
                </label>
              </div>
            </div>
            <div className="col-xl-8 ">
              {
                methods.control._formValues.visual && (
                  <div className="chart">
                    <LineChart
                      data={{
                        labels: methods.control._formValues.graph?.labels ?? [],
                        // labels: Array.from(new Set([
                        //     ...methods.control._formValues.graph?.ethanol?.map((item: any) => item.x),
                        //     ...methods.control._formValues.graph?.methanol?.map((item: any) => item.x),
                        // ])).sort((a: number, b: number) => a > b ? 1 : -1),
                        datasets: [
                          {
                            label: "Ethanol",
                            data: methods.control._formValues.graph?.ethanol ?? [],
                            fill: true,
                            backgroundColor: "rgba(255,36,42,0.2)",
                            borderColor: "rgba(255,36,42,1)"
                          },
                          {
                            label: "Methanol",
                            data: methods.control._formValues.graph?.methanol ?? [],
                            fill: true,
                            backgroundColor: "rgba(0,0,0,0.2)",
                            borderColor: "rgba(0,0,0,1)"
                          }
                        ]
                      }}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TakedaModels;