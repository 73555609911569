import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Controller, useFormContext } from 'react-hook-form';

// props
import { VendorProps } from '../../../types/materialsTypes';

// helpers
import { vendorNames } from '../../../utils/materialHelper';
import MaterialService from '../../../services/materialService';
import { alertCloseAction, alertOpenAction } from '../../../redux/actions';
import { apiRoutes } from '../../../utils/apiRoutes';
import { uniqueItemsByKey, sortItemsByKey } from '../../../utils/common';

const Header = (props: any) => {
    const dispatch = useDispatch();
    const methods = useFormContext();
    
    // auth
    const { authState } = useOktaAuth();
    const auth: any = authState ? authState?.accessToken : '';

    // states
    const [loading, setLoading] = useState<boolean>(false);
    const [materialNameOptions, setMaterialNameOptions] = useState<any[]>([]);
    const [lotNumberOptions, setLotNumberOptions] = useState<any[]>([]);
    const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);

    useEffect(() => {
        methods.watch();
    });

    useEffect(() => {
        if (props.user) {
            const { userType, vendors } = props.user;
            if (userType.includes('external')) {
                // okta dashboard vendors for external users.
                setVendors(
                    sortItemsByKey([
                        { label: 'Generic', value: 'Generic' },
                        ...vendors.map((vendor: VendorProps) => ({ label: vendor, value: vendor })),
                    ], 'label'));
            }
        }
    }, [props.user]);

    useEffect(() => {
        const { vendor_name, material_name, lot_number } = methods.control._formValues;
        if (vendor_name?.length && !material_name?.length && !lot_number?.length) {
            setMaterialNameOptions([]);
            getMaterialNames(vendor_name);
        } else if (vendor_name?.length && material_name?.length && !lot_number?.length) {
            setLotNumberOptions([]);
            getLotNumbers(vendor_name, material_name);
        } else if (vendor_name?.length && material_name?.length && lot_number?.length) {
            props?.handleSelectedFields({
                loading,
                vendor_name,
                material_name,
                lot_number,
            });
        }
    }, [
        methods.control._formValues.vendor_name,
        methods.control._formValues.material_name,
        methods.control._formValues.lot_number,
    ]);

    // method to return material list based on selected vendor_name field.
    const getMaterialNames = async (vendor_name: string) => {
        const payload = {
            uid: `${auth?.claims?.uid}`,
            vendor_name,
        };
        setLoading(true); // enable loading
        const res = await MaterialService.create(apiRoutes.LIST_MATERIAL_NAMES_MATERIAL, payload);
        setLoading(false); // disable loading
        if (res?.data?.code == 200) {
            if (res?.data?.body?.length) {
                setMaterialNameOptions(
                    sortItemsByKey(uniqueItemsByKey(res?.data?.body, 'material_name'), 'material_name'));
            }
        } else {
            dispatch(
                alertOpenAction("No related materials present.", "error")
            );
            setTimeout(() => dispatch(alertCloseAction()));
        };
    };

    // method to return lot number based on selected vendor_name and material_name fields.
    const getLotNumbers = async (vendor_name: string, material_name: string) => {
        const payload = {
            uid: `${auth?.claims?.uid}`,
            vendor_name,
            material_name,
        };
        setLoading(true); // enable loading
        const res = await MaterialService.create(apiRoutes.LIST_LOT_NUMBERS_MATERIAL, payload);
        setLoading(false); // disable loading
        if (res?.data?.code === 200) {
            if (res?.data?.body?.length) {
                setLotNumberOptions(
                    sortItemsByKey(uniqueItemsByKey(res?.data?.body, 'lot_number'), 'lot_number'));
            }
        } else {
            dispatch(alertOpenAction('Unable to load the related Lot Numbers.', 'error'));
            setTimeout(() => dispatch(alertCloseAction()));
        };
    };

    return (
        <div className="row">
            <div className="col-lg-4 col-md-6">
                <div className="form-group">
                    <label className="ip-wrap required" htmlFor="vendor">
                        <span className="form-label">Vendor</span>
                        <div className="input-wrap select-outer">
                            <Controller
                                name="vendor_name"
                                control={methods.control}
                                defaultValue={methods.control._formValues.vendor_name}
                                render={({ field }) => (
                                    <select className="theme-ip" {...field}>
                                        <option value="" disabled={true}>Select</option>
                                        {vendors?.map((vendor: VendorProps, index: number) => (
                                            <option key={index} value={vendor?.value}>{vendor.label}</option>
                                        ))}
                                    </select>
                                )}
                            />
                        </div>
                    </label>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="form-group">
                    <label className="ip-wrap required" htmlFor="material_name">
                        <span className="form-label">Material Name</span>
                        <div className="input-wrap select-outer">
                            <Controller
                                name="material_name"
                                control={methods.control}
                                defaultValue={methods.control._formValues.material_name}
                                render={({ field }) =>
                                    <select className="theme-ip" disabled={methods.control._formValues.vendor_name ? false : true} {...field}>
                                        <option value="" disabled={true}>Select</option>
                                        {materialNameOptions?.map((name: any, index: number) => (
                                            <option key={index} value={name.material_name}>{name?.material_name}</option>
                                        ))}
                                    </select>
                                }
                            />
                        </div>
                    </label>
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="form-group ">
                    <label className="ip-wrap required" htmlFor="lot_number" title={"Lot Number"}>
                        <span className="form-label">Lot Number</span>
                        <div className="input-wrap select-outer">
                            <Controller
                                name="lot_number"
                                control={methods.control}
                                defaultValue={methods.control._formValues.lot_number}
                                render={({ field }) =>
                                    <select className="theme-ip" disabled={methods.control._formValues?.vendor_name && methods.control._formValues?.material_name ? false : true} {...field}>
                                        <option value="" disabled={true}>Select</option>
                                        {lotNumberOptions?.map((name: any, index: number) => (
                                            <option key={index} value={name.lot_number}>{name?.lot_number}</option>
                                        ))}
                                    </select>}
                            />
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default Header;