import { parseAttachments } from ".";
import { EquipmentOwners, EquipmentTypes } from "./equipmentData";

const attachmentsTypes = [
    { label: 'Drawing', value: 'Drawing' },
    { label: 'Manufacturer Manual', value: 'Manufacturer Manual' },
    { label: 'SOP', value: 'SOP' },
    { label: 'Service Log', value: 'Service Log' },
];

const getEquipmentsTypesByKey = () => {
    return EquipmentTypes.reduce((e: any, t: any) => {
        e[t.value] = t;
        return e;
    }, {});
}

const onlyUnique = (value: any, index: any, array: any) => {
    return array.indexOf(value) === index;
}

const getLabelFromValue = (allEquipments: any, key: string) => {
    let sortedArr: any = [];
    switch (key) {
        case 'type':
            allEquipments.map((item: any) => {
                sortedArr.push(item.equipment_type)
                return sortedArr;
            })
            break;
        case 'owners':
            allEquipments.map((item: any) => {
                sortedArr.push(item.equipment_owner)
                return sortedArr;
            })
            break;
    }
    sortedArr = sortedArr.filter(onlyUnique);
    return sortedArr;
}

const labelObject = (sortedArr: any, key: string) => {
    let obj: any = {};
    let obj2: any = [];
    let items: any[] = [];
    switch (key) {
        case 'type':
            items = EquipmentTypes;
            break;
        case 'owners':
            items = EquipmentOwners;
            break;
    }

    const res = items.filter(x => sortedArr.some((y: any) => y === x.value));
    sortedArr.map((item: any) => {
        res.map((arr: any) => {
            if (arr.value == item) {
                obj[item] = arr.label
                obj2.push({label:arr.label,value:item})
            }
        })
    })

    return obj;
    // return res.map(x => x.label);
}

const labelValueArray = (sortedArr: any, key: string) => {
    let objArr: any = [];
    let items: any[] = [];
    switch (key) {
        case 'type':
            items = EquipmentTypes;
            break;
        case 'owners':
            items = EquipmentOwners;
            break;
    }

    const res = items.filter(x => sortedArr.some((y: any) => y === x.value));
    sortedArr.map((item: any) => {
        res.map((arr: any) => {
            if (arr.value == item) {
                objArr.push({label:arr.label,value:item})
            }
        })
    })

    return objArr;
    // return res.map(x => x.label);
}

const parseEquipmentFieldsExport: any = (value: boolean) =>
Object.keys(equipmentFields).length && Object.keys(equipmentFields).reduce(
    (p: any[], q: any) => {
        p[q] = equipmentFields[q].reduce((e: any, t: any) => {
            e[t.value] = value;
            return e;
        }, {})
        return p;
}, []);

const equipmentSolubilityInitialData: any = {
    equipment_id: "",
    equipment_owner: "",
    equipment_type: "",
    tag_number: "",
    simulation_type: "",
    cfd_model_folders: "",
    ht_model_folders: "",
    location: "",
    brand: "",
    manufacturer_model_number: "",
    material_of_construction: "",
    application_and_scale: "",
    cfd: {
      ccmFiles: [],
      comsolFiles: [],
      mStarFiles: [],
      velocityScreenshot: [],
      shearScreenshot: [],
      concentrationScreenshot: [],
      otherImages: [],
    },
    ht: {
      cfdFiles: [],
      dynochemFiles: [],
      pythonFiles: [],
      cfdScreenshot: [],
      empiricalModels: [],
      experimentalMeasurement: [],
      otherModels: [],
    },
}

const equipmentFormByTypes: any = {
    vessel: {
        total_volume: false,
        total_volume_options: {
            operation: "",
            value: "",
        },
        id: false,
        id_options: {
            operation: "",
            value: "",
        },
        rated_pressure: false,
        rated_pressure_options: {
            operation: "",
            value: ""
        },
    },
    reactor: {
        total_volume: false,
        total_volume_options: {
            operation: "",
            value: ""
        },
        id: false,
        id_options: {
            operation: "",
            value: ""
        },
        rated_pressure: false,
        rated_pressure_options: {
            operation: "",
            value: ""
        },
        heat_exchanger: false,
    },
    distillation: {
        type: false,
        total_volume: false,
        id: false,
        packing_type: false,
        tray_type: false,
        total_volume_options: {
            operation: "",
            value: ""
        },
        id_options: {
            operation: "",
            value: ""
        },
        packing_type_options: "",
        tray_type_options: "",
    },
    filtration: {
        type: false,
        mesh_size: false,
        mesh_size_options: {
            operation: "",
            value: ""
        },
        id: false,
        id_options: {
            operation: "",
            value: ""
        },
        liquid_volume_capacity: false,
        liquid_volume_capacity_options: {
            operation: "",
            value: ""
        },
        solid_capacity: false,
        solid_capacity_options: {
            operation: "",
            value: ""
        },
    },
    evaporator: {
        type: false,
        total_volume: false,
        total_volume_options: {
            operation: "",
            value: ""
        },
        pressure: false,
        pressure_options: {
            operation: "",
            value: ""
        },
        solvent_recovery: null,
    },
    mixer: {
        type: false,
        mixer_diameter: false,
        shaft_length: false,
        type_options: "",
        mixer_diameter_options: {
            operation: "",
            value: ""
        },
        shaft_length_options: {
            operation: "",
            value: ""
        },
    },
}

const initialEquipmentData = (equipment: any) => {
    return {
        equipment_type: equipment?.equipment_type ?? '',
        equipment_owner: equipment?.equipment_owner ?? '',
        tag_number: equipment?.tag_number ?? null,
        location: equipment?.location ?? null,
        brand: equipment?.brand ?? null,
        manufacturer_model_number: equipment?.manufacturer_model_number ?? null,
        material_of_construction: equipment?.material_of_construction ?? null,
        application_and_scale: equipment?.application_and_scale ?? '',
        vessel: {
            total_volume: equipment?.vessel?.total_volume?.toString() ?? '',
            working_volume: equipment?.vessel?.working_volume?.toString() ?? '',
            height: equipment?.vessel?.height?.toString() ?? '',
            id: equipment?.vessel?.id?.toString() ?? '',
            od: equipment?.vessel?.od?.toString() ?? '',
            wall_thickness: equipment?.vessel?.wall_thickness?.toString() ?? '',
            number_of_ports: equipment?.vessel?.number_of_ports?.toString() ?? '',
            rated_pressure: equipment?.vessel?.rated_pressure?.toString() ?? '',
            discharge_location: equipment?.vessel?.discharge_location?.toString() ?? '',
            insulated: equipment?.vessel?.insulated?.toString() ?? '',
            heating_cooling: equipment?.vessel?.heating_cooling?.toString() ?? '',
            heat_exchanger: equipment?.vessel?.heat_exchanger?.toString() ?? '',
            bottom: equipment?.vessel?.bottom?.toString() ?? '',
            auxiliary_units: equipment?.vessel?.auxiliary_units?.toString() ?? '',
            cip: equipment?.vessel?.cip?.toString() ?? '',
            attachments: {
                drawing: equipment?.vessel?.attachments?.drawing?.length ? parseAttachments(equipment?.vessel?.attachments?.drawing) : [],
                manufacturer_manual: equipment?.vessel?.attachments?.manufacturer_manual?.length ? parseAttachments(equipment?.vessel?.attachments?.manufacturer_manual) : [],
                sop: equipment?.vessel?.attachments?.sop?.length ? parseAttachments(equipment?.vessel?.attachments?.sop) : [],
                service_log: equipment?.vessel?.attachments?.service_log?.length ? parseAttachments(equipment?.vessel?.attachments?.service_log) : [],
            }
        },
        reactor: {
            total_volume: equipment?.reactor?.total_volume?.toString() ?? '',
            working_volume: equipment?.reactor?.working_volume?.toString() ?? '',
            height: equipment?.reactor?.height?.toString() ?? '',
            id: equipment?.reactor?.id?.toString() ?? '',
            od: equipment?.reactor?.od?.toString() ?? '',
            wall_thickness: equipment?.reactor?.wall_thickness?.toString() ?? '',
            number_of_ports: equipment?.reactor?.number_of_ports?.toString() ?? '',
            rated_pressure: equipment?.reactor?.rated_pressure?.toString() ?? '',
            discharge_location: equipment?.reactor?.discharge_location ?? '',
            insulated: equipment?.reactor?.insulated ?? '',
            heating_cooling: equipment?.reactor?.heating_cooling ?? '',
            heat_exchanger: equipment?.reactor?.heat_exchanger ?? '',
            bottom: equipment?.reactor?.bottom ?? '',
            auxiliary_units: equipment?.reactor?.auxiliary_units ?? '',
            cip: equipment?.reactor?.cip ?? '',
            attachments: {
                drawing: equipment?.reactor?.attachments?.drawing?.length ? parseAttachments(equipment?.reactor?.attachments?.drawing) : [],
                manufacturer_manual: equipment?.reactor?.attachments?.drawing?.length ? parseAttachments(equipment?.reactor?.attachments?.drawing) : [],
                sop: equipment?.reactor?.attachments?.drawing?.length ? parseAttachments(equipment?.reactor?.attachments?.drawing) : [],
                service_log: equipment?.reactor?.attachments?.drawing?.length ? parseAttachments(equipment?.reactor?.attachments?.drawing) : [],
            }
        },
        distillation: {
            type: equipment?.distillation?.type?.toString() ?? '',
            total_volume: equipment?.distillation?.total_volume?.toString() ?? '',
            id: equipment?.distillation?.id?.toString() ?? '',
            height: equipment?.distillation?.height?.toString() ?? '',
            heat_exchanger: equipment?.distillation?.heat_exchanger?.toString() ?? '',
            number_of_ports: equipment?.distillation?.number_of_ports?.toString() ?? '',
            packing_type: equipment?.distillation?.packing_type?.toString() ?? '',
            packing_material: equipment?.distillation?.packing_material?.toString() ?? '',
            tray_type: equipment?.distillation?.tray_type?.toString() ?? '',
            auxiliary_units: equipment?.distillation?.auxiliary_units?.toString() ?? '',
            attachments: {
                drawing: equipment?.distillation?.attachments?.drawing?.length ? parseAttachments(equipment?.distillation?.attachments?.drawing) : [],
                manufacturer_manual: equipment?.distillation?.attachments?.drawing?.length ? parseAttachments(equipment?.distillation?.attachments?.drawing) : [],
                sop: equipment?.distillation?.attachments?.drawing?.length ? parseAttachments(equipment?.distillation?.attachments?.drawing) : [],
                service_log: equipment?.distillation?.attachments?.drawing?.length ? parseAttachments(equipment?.distillation?.attachments?.drawing) : [],
            }
        },
        filtration: {
            type: equipment?.filtration?.type?.toString() ?? '',
            filter_material: equipment?.filtration?.filter_material?.toString() ?? '',
            mesh_size: equipment?.filtration?.mesh_size?.toString() ?? '',
            id: equipment?.filtration?.id?.toString() ?? '',
            liquid_volume_capacity: equipment?.filtration?.liquid_volume_capacity?.toString() ?? '',
            solid_capacity: equipment?.filtration?.solid_capacity?.toString() ?? '',
            max_filter_cake_thickness: equipment?.filtration?.max_filter_cake_thickness?.toString() ?? '',
            solvent_wash: equipment?.filtration?.solvent_wash?.toString() ?? '',
            working_pressure: equipment?.filtration?.working_pressure?.toString() ?? '',
            filter_backwash: equipment?.filtration?.filter_backwash?.toString() ?? '',
            heated: equipment?.filtration?.heated?.toString() ?? '',
            mixer: equipment?.filtration?.mixer?.toString() ?? '',
            cip: equipment?.filtration?.cip?.toString() ?? '',
            attachments: {
                drawing: equipment?.filtration?.attachments?.drawing?.length ? parseAttachments(equipment?.filtration?.attachments?.drawing) : [],
                manufacturer_manual: equipment?.filtration?.attachments?.drawing?.length ? parseAttachments(equipment?.filtration?.attachments?.drawing) : [],
                sop: equipment?.filtration?.attachments?.drawing?.length ? parseAttachments(equipment?.filtration?.attachments?.drawing) : [],
                service_log: equipment?.filtration?.attachments?.drawing?.length ? parseAttachments(equipment?.filtration?.attachments?.drawing) : [],
            }
        },
        mixer: {
            type: equipment?.mixer?.type?.toString() ?? '',
            number_of_mixing_element: equipment?.mixer?.number_of_mixing_element?.toString() ?? '',
            number_of_blades: equipment?.mixer?.number_of_blades?.toString() ?? '',
            mixer_angle: equipment?.mixer?.mixer_angle?.toString() ?? '',
            blade_angle: equipment?.mixer?.blade_angle?.toString() ?? '',
            blade_vertical_height: equipment?.mixer?.blade_vertical_height?.toString() ?? '',
            mixer_diameter: equipment?.mixer?.mixer_diameter?.toString() ?? '',
            blade_thickness: equipment?.mixer?.blade_thickness?.toString() ?? '',
            shaft_length: equipment?.mixer?.shaft_length?.toString() ?? '',
            shaft_od: equipment?.mixer?.shaft_od?.toString() ?? '',
            shaft_connector: equipment?.mixer?.shaft_connector?.toString() ?? '',
            motor_power: equipment?.mixer?.motor_power?.toString() ?? '',
            attachments: {
                drawing: equipment?.mixer?.attachments?.drawing?.length ? parseAttachments(equipment?.mixer?.attachments?.drawing) : [],
                manufacturer_manual: equipment?.mixer?.attachments?.drawing?.length ? parseAttachments(equipment?.mixer?.attachments?.drawing) : [],
                sop: equipment?.mixer?.attachments?.drawing?.length ? parseAttachments(equipment?.mixer?.attachments?.drawing) : [],
                service_log: equipment?.mixer?.attachments?.drawing?.length ? parseAttachments(equipment?.mixer?.attachments?.drawing) : [],
            }
        },
        evaporator: {
            type: equipment?.evaporator?.type?.toString() ?? '',
            total_volume: equipment?.evaporator?.total_volume?.toString() ?? '',
            working_volume: equipment?.evaporator?.working_volume?.toString() ?? '',
            pressure: equipment?.evaporator?.pressure?.toString() ?? '',
            scrapper: equipment?.evaporator?.scrapper?.toString() ?? '',
            surface_area: equipment?.evaporator?.surface_area?.toString() ?? '',
            max_viscosity: equipment?.evaporator?.max_viscosity?.toString() ?? '',
            feeding_method: equipment?.evaporator?.feeding_method?.toString() ?? '',
            solvent_recovery: equipment?.evaporator?.solvent_recovery?.toString() ?? '',
            heating: equipment?.evaporator?.heating?.toString() ?? '',
            cip: equipment?.evaporator?.cip?.toString() ?? '',
            attachments: {
                drawing: equipment?.evaporator?.attachments?.drawing?.length ? parseAttachments(equipment?.evaporator?.attachments?.drawing) : [],
                manufacturer_manual: equipment?.evaporator?.attachments?.drawing?.length ? parseAttachments(equipment?.evaporator?.attachments?.drawing) : [],
                sop: equipment?.evaporator?.attachments?.drawing?.length ? parseAttachments(equipment?.evaporator?.attachments?.drawing) : [],
                service_log: equipment?.evaporator?.attachments?.drawing?.length ? parseAttachments(equipment?.evaporator?.attachments?.drawing) : [],
            }
        }
    }
};

const equipmentFields: any = {
    general: [
        {
            name: 'Equipment Type', value: 'equipment_type',
        },
        {
            name: 'Equipment Owner', value: 'equipment_owner',
        },
        {
            name: 'Tag Number', value: 'tag_number',
        },
        {
            name: 'Location', value: 'location',
        },
        {
            name: 'Brand', value: 'brand',
        },
        {
            name: 'Manufacturer Model Number', value: 'manufacturer_model_number',
        },
        {
            name: 'Material of Construction', value: 'material_of_construction',
        },
        {
            name: 'Application and Scale', value: 'application_and_scale',
        },
    ],
    vessel: [
        {
            name: 'Total Volume', value: 'total_volume',
        },
        {
            name: 'Working Volume', value: 'working_volume',
        },
        {
            name: 'Height', value: 'height',
        },
        {
            name: 'ID', value: 'id',
        },
        {
            name: 'OD', value: 'od',
        },
        {
            name: 'Wall Thickness', value: 'wall_thickness',
        },
        {
            name: 'Number of Ports', value: 'number_of_ports',
        },
        {
            name: 'Rated Pressure', value: 'rated_pressure',
        },
        {
            name: 'Discharge Location', value: 'discharge_location',
        },
        {
            name: 'Insulated', value: 'insulated',
        },
        {
            name: 'Heating Cooling', value: 'heating_cooling',
        },
        {
            name: 'Heat Exchanger', value: 'heat_exchanger',
        },
        {
            name: 'Bottom', value: 'bottom',
        },
        {
            name: 'Auxiliary Units', value: 'auxiliary_units',
        },
        {
            name: 'CIP', value: 'cip',
        },
    ],
    reactor: [
        {
            name: 'Total Volume', value: 'total_volume',
        },
        {
            name: 'Working Volume', value: 'working_volume',
        },
        {
            name: 'Height', value: 'height',
        },
        {
            name: 'ID', value: 'id',
        },
        {
            name: 'OD', value: 'od',
        },
        {
            name: 'Wall Thickness', value: 'wall_thickness',
        },
        {
            name: 'Number of Ports', value: 'number_of_ports',
        },
        {
            name: 'Rated Pressure', value: 'rated_pressure',
        },
        {
            name: 'Discharge Location', value: 'discharge_location',
        },
        {
            name: 'Insulated', value: 'insulated',
        },
        {
            name: 'Heating Cooling', value: 'heating_cooling',
        },
        {
            name: 'Heat Exchanger', value: 'heat_exchanger',
        },
        {
            name: 'Bottom', value: 'bottom',
        },
        {
            name: 'Auxiliary Units', value: 'auxiliary_units',
        },
        {
            name: 'CIP', value: 'cip',
        },
    ],
    distillation: [
        {
            name: 'Type', value: 'type',
        },
        {
            name: 'Total Volume', value: 'total_volume',
        },
        {
            name: 'Packing Type', value: 'packing_type',
        },
        {
            name: 'Tray Type', value: 'tray_type',
        },
        {
            name: 'Packing Material', value: 'packing_material',
        },
        {
            name: 'Height', value: 'height',
        },
        {
            name: 'ID', value: 'id',
        },
        {
            name: 'Heat Exchanger', value: 'heat_exchanger',
        },
        {
            name: 'Number of Ports', value: 'number_of_ports',
        },
        {
            name: 'Auxiliary Units', value: 'auxiliary_units',
        },
    ],
    filtration: [
        {
            name: 'Mesh Size', value: 'mesh_size',
        },
        {
            name: 'ID', value: 'id',
        },
        {
            name: 'Liquid Volume Capacity', value: 'liquid_volume_capacity',
        },
        {
            name: 'Solid Capacity', value: 'solid_capacity',
        },
        {
            name: 'Max Filter Cake Thickness', value: 'max_filter_cake_thickness',
        },
        {
            name: 'Solvent Wash', value: 'solvent_wash',
        },
        {
            name: 'Working Pressure', value: 'working_pressure',
        },
        {
            name: 'Filter Backwash', value: 'filter_backwash',
        },
        {
            name: 'Heated', value: 'heated',
        },
        {
            name: 'Mixer', value: 'mixer',
        },
        {
            name: 'CIP', value: 'cip',
        },
        {
            name: 'Type', value: 'type',
        },
        {
            name: 'Filter Material', value: 'filter_material',
        },
    ],
    mixer: [
        {
            name: 'Type', value: 'type',
        },
        {
            name: 'Number of Mixing Element', value: 'number_of_mixing_element',
        },
        {
            name: 'Number of Blades', value: 'number_of_blades',
        },
        {
            name: 'Mixer Angle', value: 'mixer_angle',
        },
        {
            name: 'Blade Angle', value: 'blade_angle',
        },
        {
            name: 'Blade Vertical Height', value: 'blade_vertical_height',
        },
        {
            name: 'Mixer Diameter', value: 'mixer_diameter',
        },
        {
            name: 'Blade Thickness', value: 'blade_thickness',
        },
        {
            name: 'Shaft Length', value: 'shaft_length',
        },
        {
            name: 'Shaft OD', value: 'shaft_od',
        },
        {
            name: 'Shaft Connector', value: 'shaft_connector',
        },
        {
            name: 'Motor Power', value: 'motor_power',
        },
    ],
    evaporator: [
        {
            name: 'Type', value: 'type',
        },
        {
            name: 'Total Volume', value: 'total_volume',
        },
        {
            name: 'Working Volume', value: 'working_volume',
        },
        {
            name: 'Pressure', value: 'pressure',
        },
        {
            name: 'Scrapper', value: 'scrapper',
        },
        {
            name: 'Surface Area', value: 'surface_area',
        },
        {
            name: 'Max Viscosity', value: 'max_viscosity',
        },
        {
            name: 'Feeding Method', value: 'feeding_method',
        },
        {
            name: 'Solvent Recovery', value: 'solvent_recovery',
        },
        {
            name: 'Heating', value: 'heating',
        },
        {
            name: 'CIP', value: 'cip',
        },
    ],
}

export const equipmentFiltersData = (_formValues: any) => {
    const {
        types_checkbox,
        owners_checkbox,
        application_scale_checkbox,
        types_properties_checkbox,
        equipment_types,
        // equipment_owners,
        // application_scale_options,
        distillation_checkbox,
        evaporator_checkbox,
        filtration_checkbox,
        mixer_checkbox,
        reactor_checkbox,
        vessel_checkbox,
    } = _formValues;

    // copy form values
    let newFilters = { ..._formValues };

    if (!types_checkbox) {
        newFilters.equipment_types = [];
    }

    if (!owners_checkbox) {
        newFilters.equipment_owners = [];
    }

    if (!application_scale_checkbox) {
        newFilters.application_scale_options = [];
    }

    // if (types_checkbox && !equipment_types?.length) {
    //     newFilters.types_checkbox = false;
    // }

    // if (owners_checkbox && !equipment_owners?.length) {
    //     newFilters.owners_checkbox = false;
    // }

    // if (application_scale_checkbox && !application_scale_options?.length) {
    //     newFilters.application_scale_checkbox = false;
    // }

    if (!types_properties_checkbox && equipment_types?.length) {
        const item: any = {};
        newFilters.distillation_checkbox = false;
        newFilters.evaporator_checkbox = false;
        newFilters.filtration_checkbox = false;
        newFilters.mixer_checkbox = false;
        newFilters.reactor_checkbox = false;
        newFilters.vessel_checkbox = false;
        newFilters.active = equipment_types.reduce((e: any, t: any) => {
            item[t.value] = equipmentFormByTypes[t.value];
            e = { ...e, ...item }
            return e;
        }, {});
    }
    
    if (types_properties_checkbox && equipment_types?.length) {
        const selectedTypesByKey = equipment_types.reduce((e: any, t: any) => {
            e[t.value] = t;
            return e;
        }, {});

        if (!distillation_checkbox && selectedTypesByKey.hasOwnProperty('distillation')) {
            newFilters.active = {
                ...newFilters.active,
                distillation: equipmentFormByTypes.distillation,
            };
        } 
        if (!evaporator_checkbox && selectedTypesByKey.hasOwnProperty('evaporator')) {
            newFilters.active = {
                ..._formValues.active,
                evaporator: equipmentFormByTypes.evaporator,
            };
        }
        if (!filtration_checkbox && selectedTypesByKey.hasOwnProperty('filtration')) {
            newFilters.active = {
                ..._formValues.active,
                filtration: equipmentFormByTypes.filtration,
            };
        }
        if (!mixer_checkbox && selectedTypesByKey.hasOwnProperty('mixer')) {
            newFilters.active = {
                ..._formValues.active,
                mixer: equipmentFormByTypes.mixer,
            };
        }
        if (!reactor_checkbox && selectedTypesByKey.hasOwnProperty('reactor')) {
            newFilters.active = {
                ..._formValues.active,
                reactor: equipmentFormByTypes.reactor,
            };
        }
        if (!vessel_checkbox && selectedTypesByKey.hasOwnProperty('vessel')) {
            newFilters.active = {
                ..._formValues.active,
                vessel: equipmentFormByTypes.vessel,
            };
        }

        if (distillation_checkbox) {
            if (!newFilters?.active?.distillation?.type) {
                newFilters.active.distillation.type_options = equipmentFormByTypes.distillation.type_options;
            }
            if (!newFilters?.active?.distillation?.total_volume) {
                newFilters.active.distillation.total_volume_options = equipmentFormByTypes.distillation.total_volume_options;
            }
            if (!newFilters?.active?.distillation?.id) {
                newFilters.active.distillation.id_options = equipmentFormByTypes.distillation.id_options;
            }
            if (!newFilters?.active?.distillation?.packing_type) {
                newFilters.active.distillation.packing_type_options = equipmentFormByTypes.distillation.packing_type_options;
            }
            if (!newFilters?.active?.distillation?.tray_type) {
                newFilters.active.distillation.tray_type_options = equipmentFormByTypes.distillation.tray_type_options;
            }
        }

        if (evaporator_checkbox) {
            if (!newFilters?.active?.evaporator?.type) {
                newFilters.active.evaporator.type_options = equipmentFormByTypes.evaporator.type_options;
            }
            if (!newFilters?.active?.evaporator?.total_volume) {
                newFilters.active.evaporator.total_volume_options = equipmentFormByTypes.evaporator.total_volume_options;
            }
            if (!newFilters?.active?.evaporator?.pressure) {
                newFilters.active.evaporator.pressure_options = equipmentFormByTypes.evaporator.pressure_options;
            }
            if (!newFilters?.active?.evaporator?.solvent_recovery) {
                newFilters.active.evaporator.solvent_recovery_options = equipmentFormByTypes.evaporator.solvent_recovery_options;
            }
        }

        if (filtration_checkbox) {
            if (!newFilters?.active?.filtration?.type) {
                newFilters.active.filtration.type_options = equipmentFormByTypes.filtration.type_options;
            }
            if (!newFilters?.active?.filtration?.mesh_size) {
                newFilters.active.filtration.mesh_size_options = equipmentFormByTypes.filtration.mesh_size_options;
            }
            if (!newFilters?.active?.filtration?.id) {
                newFilters.active.filtration.id_options = equipmentFormByTypes.filtration.id_options;
            }
            if (!newFilters?.active?.filtration?.liquid_volume_capacity) {
                newFilters.active.filtration.liquid_volume_capacity_options = equipmentFormByTypes.filtration.liquid_volume_capacity_options;
            }
            if (!newFilters?.active?.filtration?.solid_capacity) {
                newFilters.active.filtration.solid_capacity_options = equipmentFormByTypes.filtration.solid_capacity_options;
            }
        }

        if (mixer_checkbox) {
            if (!newFilters?.active?.mixer?.type) {
                newFilters.active.mixer.type_options = equipmentFormByTypes.mixer.type_options;
            }
            if (!newFilters?.active?.mixer?.mixer_diameter) {
                newFilters.active.mixer.mixer_diameter_options = equipmentFormByTypes.mixer.mixer_diameter_options;
            }
            if (!newFilters?.active?.mixer?.shaft_length) {
                newFilters.active.mixer.shaft_length_options = equipmentFormByTypes.mixer.shaft_length_options;
            }
        }

        if (reactor_checkbox) {
            if (!newFilters?.active?.reactor?.total_volume) {
                newFilters.active.reactor.total_volume_options = equipmentFormByTypes.reactor.total_volume_options;
            }
            if (!newFilters?.active?.reactor?.id) {
                newFilters.active.reactor.id_options = equipmentFormByTypes.reactor.id_options;
            }
            if (!newFilters?.active?.mixer?.rated_pressure) {
                newFilters.active.reactor.rated_pressure_options = equipmentFormByTypes.reactor.rated_pressure_options;
            }
            if (!newFilters?.active?.mixer?.heat_exchanger) {
                newFilters.active.reactor.heat_exchanger_options = equipmentFormByTypes.reactor.heat_exchanger_options;
            }
        }

        if (vessel_checkbox) {
            if (!newFilters?.active?.vessel?.total_volume) {
                newFilters.active.vessel.total_volume_options = equipmentFormByTypes.vessel.total_volume_options;
            }
            if (!newFilters?.active?.vessel?.id) {
                newFilters.active.vessel.id_options = equipmentFormByTypes.vessel.id_options;
            }
            if (!newFilters?.active?.vessel?.rated_pressure) {
                newFilters.active.vessel.rated_pressure_options = equipmentFormByTypes.vessel.rated_pressure_options;
            }
        }
    }
    return newFilters;
}

const parseEquipmentFields: any = Object.keys(equipmentFields).length && Object.keys(equipmentFields).reduce(
    (p: any[], q: any) => {
        p[q] = equipmentFields[q].reduce((e: any, t: any) => {
            e[t.value] = false;
            return e;
        }, {})
        return p;
}, []);

const getUpdatedFiles = (oldFiles: any[], newFiles: any[]) => {
    return newFiles?.reduce((e: any[], t: any) => {
        let newItem = oldFiles.find((attachment: any) => (attachment.category === t.category && attachment.display_name === t?.file?.name.toLocaleLowerCase().replaceAll(' ', '-')));
        if (!newItem) {
            e = [...e, t];
        }
        return e;
    }, []);
}

const getAttachments = (equipment: any, data: any) => {
    return Object.values([
        ...getUpdatedFiles(Object.values(equipment?.vessel?.attachments ?? []).reduce((e: any[], t: any) => {
            e = [...e, ...t];
            return e;
        }, []), Object.values(data?.vessel?.attachments ?? []).reduce((e: any[], t: any) => {
            e = [...e, ...t];
            return e;
        }, [])),
        ...getUpdatedFiles(Object.values(equipment?.reactor?.attachments ?? []).reduce((e: any[], t: any) => {
            e = [...e, ...t];
            return e;
        }, []),
            Object.values(data?.reactor?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, [])),
        ...getUpdatedFiles(
            Object.values(equipment?.distillation?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, []),
            Object.values(data?.distillation?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, [])),
        ...getUpdatedFiles(
            Object.values(equipment?.filtration?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, []),
            Object.values(data?.filtration?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, [])),
        ...getUpdatedFiles(
            Object.values(equipment?.mixer?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, []),
            Object.values(data?.mixer?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, [])),
        ...getUpdatedFiles(
            Object.values(equipment?.evaporator?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, []),
            Object.values(data?.evaporator?.attachments ?? []).reduce((e: any[], t: any) => {
                e = [...e, ...t];
                return e;
            }, [])),
    ]);
}

export {
    attachmentsTypes,
    getEquipmentsTypesByKey,
    onlyUnique,
    getLabelFromValue,
    labelObject,
    labelValueArray,
    parseEquipmentFieldsExport,
    equipmentSolubilityInitialData,
    initialEquipmentData,
    equipmentFields,
    parseEquipmentFields,
    getUpdatedFiles,
    getAttachments,
    equipmentFormByTypes,
}