import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";

// components
import Layout from "../../components/layout";
import EquipmentHeader from "../../components/equipment/Header";
import FindGeneral from "../../components/equipment/FindGeneral";
import UploadCfdModels from "../../components/solubility/uploadCfdModels";
import UploadHtModels from "../../components/solubility/uploadHtModels";

// modals
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

//services
import EquipmentService from "../../services/equipmentService";
import FileService  from "../../services/fileService";

// redux action
import { equipmentAction } from "../../redux/actions/equipmentActions";
import { alertOpenAction, alertCloseAction } from "../../redux/actions";

// props
import { RootState } from "../../redux/store";
import { ResponseProps } from "../../types";

// helpers
import { UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { findFile, getUpdatedFiles, uniqueItemsByKey } from "../../utils/common";
import { EquipmentModelsHelp } from "../../utils/helpContent";
import { apiRoutes } from "../../utils/apiRoutes";
import { checkDuplicateFiles } from "../../utils/materialHelper";
import { equipmentSolubilityInitialData } from "../../utils/equipmentHelper";
import {
  NEW_EQUIPMENT_MODELS_SUCCESS_MESSAGE,
  UPDATE_EQUIPMENT_MODEL_SUCCESS_MESSAGE,
  ERROR_MESSAGE
} from "../../utils/constant";

const cfdMoelInitialData = equipmentSolubilityInitialData.cfd;
const htModelInitialData = equipmentSolubilityInitialData.ht;

const attachmentTypes: any = {
  cfd: {
    ccmFiles: "CCM Files",
    comsolFiles: "COMSOL Files",
    mStarFiles: "M-Star Files",
    velocityScreenshot: "Velocity Screenshot",
    shearScreenshot: "Shear Screenshot",
    concentrationScreenshot: "Concentration Screenshot",
    otherImages: "Other Images",
  },
  ht: {
    cfdFiles: "CFD Files",
    dynochemFiles: "Dynochem Files",
    pythonFiles: "Python Files",
    cfdScreenshot: "CFD Screenshot",
    empiricalModels: "Empirical Models",
    experimentalMeasurement: "Experimental Measurement",
    otherModels: "Other Models",
  },
};

const EquipmentModels = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // user context
  const { user } = useUser();

  const equipment = useSelector((state: RootState) => state.equipment.item);

  const methods = useForm({ defaultValues: equipmentSolubilityInitialData });

  const [loading, setLoading] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const [currentAttachmentType, setCurrentAttachmentType] = useState<string>("cfd");
  const [simulation, setSimulation] = useState<any>(null);

  useEffect(() => {
    methods.watch();
  });

  useEffect(() => {
    if (simulation) resetUpload(simulation);
  }, [simulation]);

  useEffect(() => {
    if (equipment && !Array.isArray(equipment)) {
      if (equipment?.equipment_type !== methods.control._formValues?.equipment_type) {
        // methods.setValue("equipment_owner", ''); // clear equipment_owner field
        // methods.setValue("tag_number", '');      // clear tag_number field
        // methods.setValue("cfd", cfdMoelInitialData);
        // methods.setValue("ht", htModelInitialData);
        dispatch(equipmentAction(null));
        setSimulation(null);
        methods.reset({
          ...methods.control._formValues,
          equipment_owner: "",
          tag_number: "",
          cfd: {
            ccmFiles: [],
            comsolFiles: [],
            mStarFiles: [],
            velocityScreenshot: [],
            shearScreenshot: [],
            concentrationScreenshot: [],
            otherImages: [],
          },
          ht: {
            cfdFiles: [],
            dynochemFiles: [],
            pythonFiles: [],
            cfdScreenshot: [],
            empiricalModels: [],
            experimentalMeasurement: [],
            otherModels: [],
          },
        }, { keepIsValid: false });
      }
      if (equipment?.equipment_owner !== methods.control._formValues?.equipment_owner) {
        // methods.setValue("tag_number", '');      // clear tag_number field
        // methods.setValue("cfd", cfdMoelInitialData);
        // methods.setValue("ht", htModelInitialData);
        dispatch(equipmentAction(null));
        setSimulation(null);
        methods.reset({
          ...methods.control._formValues,
          tag_number: "",
          cfd: {
            ccmFiles: [],
            comsolFiles: [],
            mStarFiles: [],
            velocityScreenshot: [],
            shearScreenshot: [],
            concentrationScreenshot: [],
            otherImages: [],
          },
          ht: {
            cfdFiles: [],
            dynochemFiles: [],
            pythonFiles: [],
            cfdScreenshot: [],
            empiricalModels: [],
            experimentalMeasurement: [],
            otherModels: [],
          },
        }, { keepIsValid: false });
      }
    } else {

    }
  }, [
    methods.control._formValues.equipment_type,
    methods.control._formValues.equipment_owner,
    methods.control._formValues.tag_number,
  ]);

  // method to check required fields.
  const checkRequiredFields = () => {
    return equipment ? "" : "disabled";
  };

  // method to change files format.
  const parseFile = (items: any[]) => {
    return items.map((item: any) => ({
      category: item.category,
      file: {
        name: item.display_name,
        type: item.mime_type,
      },
      error: false,
      isUpload: true,
    }));
  };

  // method to reset updated files.
  const resetUpload = (simulation: any) => {
    let data: any = { ...methods.control._formValues };
    // data.equipment_owner = simulation?.equipment_owner ?? "";
    // data.equipment_type = simulation?.equipment_type ?? "";
    // data.tag_number = simulation?.tag_number ?? "";
    // data.simulation_type = simulation?.simulation_type ?? "equipment";
    // data.cfd_model_folders = simulation?.cfd_model_folders ?? "";
    // data.ht_model_folders = simulation?.ht_model_folders ?? "";
    // data.location = equipment?.location ?? "";
    // data.brand = equipment?.brand ?? "";
    // data.manufacturer_model_number = equipment?.manufacturer_model_number ?? "";
    // data.material_of_construction = equipment?.material_of_construction ?? "";
    // data.application_and_scale = equipment?.application_and_scale ?? "";
    for (const attachment of Object.keys(methods.control._formValues?.cfd)) {
      data.cfd[attachment] = simulation?.cfd?.[attachment]
        ? parseFile(simulation?.cfd?.[attachment])
        : [];
    }

    for (const attachment of Object.keys(methods.control._formValues?.ht)) {
      data.ht[attachment] = simulation?.ht?.[attachment]
        ? parseFile(simulation?.ht?.[attachment])
        : [];
    }

    methods.setValue('cfd', data.cfd);
    methods.setValue('ht', data.ht);
  };

  // method trigger when user select files.
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue(
      `${currentAttachmentType}.${e.target.name}`,
      [
        ...(methods.control._formValues?.[currentAttachmentType]?.[
          e.target.name
        ] ?? []),
        ...Array.from(e?.target?.files ?? []).map((file: any) => {
          const fileSize = file?.size ?? 0;
          return {
            category: attachmentTypes[currentAttachmentType][e.target.name],
            field_name: e.target.name,
            file: file,
            error:
              parseFloat((fileSize / (1024 * 1024)).toFixed(2)) >
              UPLOAD_FILE_MAXLENGTH,
          };
        }),
      ],
      { shouldDirty: true, shouldTouch: true }
    );
  };

  // method trigger when user remove file.
  const removeFile = (index: number, name: string) => {
    methods.setValue(
      `${currentAttachmentType}.${name}`,
      methods.control._formValues[currentAttachmentType][name].filter(
        (file: any, i: number) => i !== index
      ),
      { shouldDirty: true, shouldTouch: true }
    );
  };

  // method trigger to download selected file.
  const downloadFile = async (file: any) => {
    let allFiles: any = Object.keys(simulation[currentAttachmentType]).reduce((e: any, t: string) => {
      if (Array.isArray(simulation[currentAttachmentType][t])) {
        e = [...e, ...simulation[currentAttachmentType][t]];
        return e;
      }
      return e;
    }, []);

    if (!file?.display_name) {
      file = allFiles.find((attachment: any) => {
        if (
          attachment.category === file.category &&
          attachment.display_name === file.file.name
        ) {
          return file;
        }
      });
    }
    if (!file) return;
    const res = await FileService.create("/files/download", {
      file: file.file_name,
    });
    if (res?.status === 200) {
      const fileRes = await fetch(res?.data?.body);
      const blob = await fileRes.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      // the filename you want
      a.download = file.display_name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      dispatch(alertOpenAction("File downloaded successfully.", "success"));
      setTimeout(() => dispatch(alertCloseAction()));
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // method trigger on when user fill all required fields.
  const handleSelectedFields = (data: any) => {
    setLoading(data.loading);
    getEquipment(data.equipment_owner, data.equipment_type, data.tag_number);
  };

  // method to return equipment
  const getEquipment = async (
    equipment_owner: string,
    equipment_type: string,
    tag_number: string
  ) => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      equipment_owner,
      equipment_type,
      tag_number,
    };
    const res = await EquipmentService.create(apiRoutes.FIND_EQUIPMENT, payload);
    if (res?.status === 200) {
      dispatch(equipmentAction(res?.data?.body));
      if (res?.data?.body?.id) getSaveSimulations(res?.data?.body?.id);
    } else {
      dispatch(alertOpenAction(ERROR_MESSAGE, "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return null;
    }
  };

  // method to get saved equipment
  const getSaveSimulations = async (id: string) => {
    setLoading(true); // enable loading
    const res = await FileService.create("/simulations/get-simulations-files-data", {
      id,
      uid: auth?.claims?.uid,
      simulation_type: "equipment",
    });
    setLoading(false); // disable loading
    if (res?.data?.code === 200 && res?.data?.body && Object.keys(res?.data?.body).length) {
      setSimulation(res?.data?.body);
      return;
    }
  };

  const uploadFile = async (file: any, attachments: any[]) => {
    let newAttachment = await findFile(attachments, file);
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], {
        type: newAttachment?.file?.type,
      });
      const res = await fetch(file.signedUrl, {
        method: "PUT",
        body: blob,
      });
      return res.ok ? true : false;
    }
    return false;
  };

  const uploadFileAPI = async (attachments: any[]) => {
    const result: ResponseProps = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    // setLoading(true); // enable loading
    const res = await FileService.create("/files/upload", {
      id: equipment?.id ?? "",
      file_for: "Equipment",
      files: attachments.map((attachment) => ({
        category: attachment?.category,
        field_name: attachment?.field_name,
        key: attachment.file?.name,
        mimeType: attachment?.file?.type,
      })),
    });
    // setLoading(false); // disable loading
    if (res?.status === 200) {
      const filesResult = res?.data?.body;
      // setLoading(true); // enable loading
      return Promise.all(
        filesResult.files.map((file: any) => uploadFile(file, attachments))
      )
        .then(async (res) => {
          // update equipment object
          filesResult.files.map((file: any) => {
            delete file.signedUrl;
            delete file.location;
            return file;
          });

          let uploadedFilesObj: any = {};
          uploadedFilesObj = { ...equipmentSolubilityInitialData?.[currentAttachmentType] };

          for (const f of filesResult?.files) {
            uploadedFilesObj[f.field_name].push(f);
          }

          let payload: any = {
            equipment_id: equipment?.id ?? "",
            equipment_type: equipment?.equipment_type ?? "",
            equipment_owner: equipment?.equipment_owner ?? "",
            tag_number: equipment?.tag_number ?? "",
            uid: equipment?.uid ?? "",
            simulation_type: "equipment",
            cfd: methods.control._formValues.cfd,
            ht: methods.control._formValues.ht,
          };

          // if (currentAttachmentType === 'ht') {
          //   payload.ht_model_folders = methods.control._formValues?.ht_model_folders ?? "ht";
          // } else {
          //   payload.cfd_model_folders = methods.control._formValues?.cfd_model_folders ?? "cfd";
          // }

          if (currentAttachmentType === 'ht') {
            payload.ht_model_folders = methods.control._formValues?.ht_model_folders ?? "";
          } else {
            payload.cfd_model_folders = methods.control._formValues?.cfd_model_folders ?? "";
          }

          for (const attachment of Object.keys(
            methods.control._formValues[currentAttachmentType]
          )) {
            payload[currentAttachmentType][attachment] = uniqueItemsByKey([
              ...await getUpdatedFiles(
              [
                ...(simulation?.[currentAttachmentType]?.[attachment] ?? []),
                ...uploadedFilesObj?.[attachment],
              ],
              methods?.control?._formValues?.[currentAttachmentType]?.[attachment] ?? [],
              !0
            )], 'file_name');
          }
          
          const equipmentResponse = await FileService.create("/simulations/save-simulations-files-data", payload);
          // setLoading(false); // disable loading
          if (equipmentResponse?.status === 200) {
            setSimulation(equipmentResponse?.data?.body??null);
            resetUpload(equipmentResponse?.data?.body??null);
            methods.reset({ ...methods.control._formValues }, { keepValues: true, keepIsValid: true });
            result.data = equipmentResponse?.data?.body??{};
            result.status = "success";
            result.message = simulation ? UPDATE_EQUIPMENT_MODEL_SUCCESS_MESSAGE : NEW_EQUIPMENT_MODELS_SUCCESS_MESSAGE;
            return result;
          }
          result.code = equipmentResponse?.data?.code??400;
          result.status = "error";
          result.message = equipmentResponse?.data?.message??ERROR_MESSAGE;
          return result;
        })
        .catch((err) => {
          result.code = 400;
          result.status = "error";
          result.message = err.message??ERROR_MESSAGE;
          return result;
        });
    }
    result.code = res?.data?.code??400;
    result.status = "error";
    result.message = res?.data?.message??ERROR_MESSAGE;
    return result;
  };

  const saveModels = async () => {
    const result: ResponseProps = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }

    const filesToUpload = Object.keys(
      methods.control._formValues[currentAttachmentType] ?? {}
    )
      .reduce((e: any, t: any) => {
        e = [...e, ...methods.control._formValues[currentAttachmentType][t]];
        return e;
      }, []);

    // updated files list
    const newAttachments = filesToUpload?.filter((file: any) => !file.isUpload);
    if (newAttachments?.length) {
      return {
        ...result,
        ...await uploadFileAPI(newAttachments),
      };
    }

    let payload: any = {
      equipment_id: equipment?.id ?? "",
      equipment_type: equipment?.equipment_type ?? "",
      equipment_owner: equipment?.equipment_owner ?? "",
      tag_number: equipment?.tag_number ?? "",
      uid: equipment?.uid ?? "",
      simulation_type: "equipment",
      // cfd_model_folders: methods.control._formValues?.cfd_model_folders ?? "",
      // ht_model_folders: methods.control._formValues?.ht_model_folders ?? "",
      cfd: equipmentSolubilityInitialData.cfd,
      ht: equipmentSolubilityInitialData.ht,
    };

    // if (currentAttachmentType === "ht") {
    //   payload.ht_model_folders = methods.control._formValues?.ht_model_folders ?? "ht";
    // } else {
    //   payload.cfd_model_folders = methods.control._formValues?.cfd_model_folders ?? "cfd";
    // }

    if (currentAttachmentType === "ht") {
      payload.ht_model_folders = methods.control._formValues?.ht_model_folders ?? "";
    } else {
      payload.cfd_model_folders = methods.control._formValues?.cfd_model_folders ?? "";
    }

    for (const attachmentKey of Object.keys(methods.control._formValues[currentAttachmentType])) {
      payload[currentAttachmentType][attachmentKey] = await getUpdatedFiles(
        simulation?.[currentAttachmentType]?.[attachmentKey] ?? [],
        methods?.control?._formValues?.[currentAttachmentType]?.[attachmentKey] ??
        [],
        !1
      );
    }

    setLoading(true); // enable loading
    const equipmentResponse = await FileService.create("/simulations/save-simulations-files-data", payload);
    setLoading(false); // disable loading
    if (equipmentResponse?.data?.code === 200) {
      setSimulation(equipmentResponse?.data?.body??null);
      resetUpload(equipmentResponse?.data?.body??null);
      methods.reset({ ...methods.control._formValues }, { keepValues: true, keepIsValid: true });
      result.data = equipmentResponse?.data?.body??{};
      result.status = "success";
      result.message = simulation ? UPDATE_EQUIPMENT_MODEL_SUCCESS_MESSAGE : NEW_EQUIPMENT_MODELS_SUCCESS_MESSAGE;
      return result;
    }
    result.code = equipmentResponse?.data?.code??400;
    result.status = "error";
    result.message = equipmentResponse?.data?.message??ERROR_MESSAGE;
    return result;
  };

  const validateForm = async () => {
    const { equipment_owner, equipment_type, tag_number } = methods.control._formValues;
    if (
      !equipment_owner?.length ||
      !equipment_type?.length ||
      !tag_number?.length
    ) {
      dispatch(alertOpenAction("Please select equipment first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    if ([0, 3, 4].includes(tabIndex)) {
      dispatch(alertOpenAction("Please select valid screen first.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    const filesToUpload = Object.keys(
      methods.control._formValues[currentAttachmentType] ?? {}
    )
      .reduce((e: any, t: any) => {
        e = [...e, ...methods.control._formValues[currentAttachmentType][t]];
        return e;
      }, []);

    // check if files are duplicate in same category.
    const isDuplicate = await (await checkDuplicateFiles(filesToUpload))?.map((item: any) => item.isDuplicate).includes(true);
    if (isDuplicate) {
      dispatch(alertOpenAction("Please remove duplicate files.", 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    return true;
  }

  // method to save data
  const onSubmit = async () => {
    if (await validateForm()) {
      if (tabIndex === 1 || tabIndex === 2) {
        setLoading(true); // enable loading
        const response = await saveModels();
        setLoading(false); // disable loading
        if (response.code === 200) {
          dispatch(alertOpenAction(response.message, "success"));
        } else {
          dispatch(alertOpenAction(response.message, 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    }
  };

  // Cancel button functions
  const saveEquipmentSolubility = async () => {
    setOpen(false);
    if (await validateForm()) {
      setLoading(true); // enable loading
      const response = await saveModels();
      setLoading(false); // disable loading
      if (response.code === 200) {
        dispatch(alertOpenAction(response.message, "success"));
      } else {
        dispatch(alertOpenAction(response.message, 'error'));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  const dontSave = () => {
    history.push('/materials');
  }

  const hasWritePermission = user?.permissions?.updated?.equipment?.hasReadAndWrite??false;

  return (
    <>
      <Layout title="Equipment Models">
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              methods.handleSubmit(onSubmit);
            }}
            noValidate
          >
            <div className="sec-info control-head">
              <div className="head">
                <h1 className="head-lg">Equipment Models</h1>
              </div>
              <div className="right">
                {hasWritePermission && (
                  <button
                    title="Save"
                    type="submit"
                    disabled={loading ? true : false}
                    className={`icon-btn ${loading ? "disabled  " : ""}`}
                    onClick={onSubmit}
                  >
                    <i className="fa-regular fa-floppy-disk" />
                  </button>
                )}
                <button className="icon-btn alter" onClick={() => {
                  if (methods.formState.isDirty || Object.keys(methods.formState.dirtyFields).length) {
                    setOpen(true);
                    return;
                  }
                  history.push(`/equipments`);
                }}>
                  <i className="fa-solid fa-xmark" />
                </button>
                <button
                  title="Help"
                  className="icon-btn alter"
                  onClick={() => {
                    setOpenHelp(true);
                  }}
                >
                  <i className="fa-solid fa-question" />
                </button>
              </div>
            </div>

            <div className="theme-card">
              <div className="body">
                <EquipmentHeader
                  user={user}
                  handleSelectedFields={handleSelectedFields}
                />
              </div>
            </div>

            <div className="theme-card">
              <FindGeneral equipment={equipment} />
            </div>

            <Tabs
              onSelect={(index: number) => {
                setTabIndex(index); // set tab index
                if (index == 1) {
                  setCurrentAttachmentType("cfd");
                }
                if (index == 2) {
                  setCurrentAttachmentType("ht");
                }
              }}
            >
              <div className="theme-card">
                <div className="body min-h-500">
                  <TabPanel></TabPanel>
                  <TabPanel>
                    {loading ? (
                      <div className="theme-loader show fixed">
                        <div className="loader-outer">
                          <div className="loader"></div>
                        </div>
                      </div>
                    ) : (
                      <UploadCfdModels
                        attachmentNames={attachmentTypes["cfd"]}
                        textField={{ cfd_model_folders: "Model Folders" }}
                        attachments={methods.control._formValues.cfd}
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        downloadFile={downloadFile}
                        hasPermission={hasWritePermission}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {loading ? (
                      <div className="theme-loader show fixed">
                        <div className="loader-outer">
                          <div className="loader"></div>
                        </div>
                      </div>
                    ) : (
                      <UploadHtModels
                        attachmentNames={attachmentTypes["ht"]}
                        textField={{ ht_model_folders: "Model Folders" }}
                        attachments={methods.control._formValues.ht}
                        handleFileChange={handleFileChange}
                        removeFile={removeFile}
                        downloadFile={downloadFile}
                        hasPermission={hasWritePermission}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    <h1>TBD...</h1>
                  </TabPanel>
                  <TabPanel>
                    <h1>TBD...</h1>
                  </TabPanel>
                </div>
                <div className="head">
                  <div className="admin-tabs mb-6 ">
                    <TabList className="inner mb-3 flex-wrap flex-col">
                      <div className="row pt-12">
                        <Tab className="col-lg-3 col-md-6 hidden">
                          <div className="theme-btn mb-6 w-full btn-md btn-md"></div>
                        </Tab>
                        <Tab className="col-lg-3 col-md-6 ">
                          <div
                            className={`theme-btn mb-6 w-full btn-md ${checkRequiredFields()}`}
                          >
                            CFD Models
                          </div>
                        </Tab>
                        <Tab className="col-lg-3 col-md-6">
                          <div
                            className={`theme-btn mb-6 w-full btn-md ${checkRequiredFields()}`}
                          >
                            HT Models
                          </div>
                        </Tab>
                        <Tab className="col-lg-3 col-md-6">
                          <div
                            className={`theme-btn mb-6 w-full btn-md ${checkRequiredFields()}`}
                          >
                            Connect to Lab
                          </div>
                        </Tab>
                        <Tab className="col-lg-3 col-md-6">
                          <div
                            className={`theme-btn mb-6 w-full btn-md ${checkRequiredFields()}`}
                          >
                            Run XX Models
                          </div>
                        </Tab>
                      </div>
                    </TabList>
                  </div>
                </div>
              </div>
            </Tabs>
          </form>
        </FormProvider>
        <CancelConfirmModal
          open={open}
          setOpen={setOpen}
          saveMaterial={saveEquipmentSolubility}
          dontSave={dontSave}
        />
        <HelpModal
          open={openHelp}
          setOpen={setOpenHelp}
          title={EquipmentModelsHelp.title}
          content={EquipmentModelsHelp.content}
        />
      </Layout>
    </>
  );
};
export default EquipmentModels;
