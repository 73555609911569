import login from "../pages/auth/Login";
export const APP_ENV = process.env.APP_ENV || "test";
const CLIENT_ID = '0oaxwa7g55eJYQu8Z357'
const ISSUER = 'https://takeda.okta.com/oauth2/auszz8gcdgXYfTMBz357'
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = '';
// const BASENAME = import.meta.env.BASE_URL || '';
// BASENAME includes trailing slash
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;
//const REDIRECT_URI = `https://dev.smpd-db.takeda.io/login/callback`;
const USE_INTERACTION_CODE = process.env.USE_INTERACTION_CODE === 'true' || false;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    useInteractionCode: true,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8080/api/messages',
  },
  app: {
    basename: BASENAME,
  },
};

