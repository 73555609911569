import React from "react";
import { Link, useHistory } from "react-router-dom";

export default function BackButton() {

  const history = useHistory();
  return <div className="back">
    <Link to="#" title="Back" className="icon-btn alter" onClick={() => {
      history.goBack();
    }}>
      <i className="fa-solid fa-arrow-left" />
    </Link>
  </div>;
}