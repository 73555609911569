import React, { useState } from "react";
import Sortable from "../../../../components/solubility/Sortable";
import { sortByField } from "../../../../utils/common";


function formatNumber(value: number) {
  return new Intl.NumberFormat(
    "en-US",
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    },
  ).format(value);
}

export default function SolubilityTable({ unchainedData }: { unchainedData: any }) {
  const csvData = unchainedData.csv_data;
  const [sortBy, setSortBy] = useState<string>("y");
  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [csvDataSorted, setCSVDataSorted] = useState(csvData);

  function manageSort(newSortBy: string) {
    let newSortOrder: "asc" | "desc" = "desc";
    if (newSortBy === sortBy) {
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setCSVDataSorted(
      sortByField(
        csvData,
        newSortBy,
        newSortOrder,
      ),
    );
  }

  return <div>
    <div className="table-outer model-3-table">
      <div className="table-responsive theme-table">
        <table className="table">
          <thead>
          <tr>
            <th>
              <Sortable title="Vial" fieldName="" sortBy={() => {
              }}
                        sortOrder={() => {
                        }}
                        manageSort={() => {
                        }}
              />
            </th>
            <th>
              <Sortable title="Temperature (C)" fieldName="" sortBy={() => {
              }}
                        sortOrder={() => {

                        }}
                        manageSort={() => {
                        }}
              />
            </th>
            <th>
              <Sortable title="Solvent" fieldName="Solvent"
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        manageSort={manageSort}
              />
            </th>
            <th>
              <Sortable title="Solubility (mg/ml)" fieldName="Solubility"
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        manageSort={manageSort}
              />
            </th>
          </tr>
          </thead>
          <tbody>
          {
            csvDataSorted.map((row: any) =>
              <tr key={row.Sample}>
                <td className="text-center">{row.Vial}</td>
                <td className="text-center">{row.Temp}</td>
                <td className="text-center">{row.Solvent}</td>
                <td className="text-center">{row.Solubility && formatNumber(row.Solubility)}</td>
              </tr>)
          }
          </tbody>
        </table>
      </div>
    </div>
  </div>;

}