import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import Layout from "../../components/layout";

// modals
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";

// helpers
import { MaterialDashboardHelp } from "../../utils/helpContent";

const MaterialIndex = () => {
  const dispatch = useDispatch();

  // user context
  const { user } = useUser();

  // state
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  useEffect(() => {
    dispatch(materialAction(null));
  }, []);

  const hasReadPermission = user?.permissions?.updated?.material?.hasRead??false;
  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite??false;

  return (
    <Layout title="Materials">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={MaterialDashboardHelp.title}
        content={MaterialDashboardHelp.content} />
      <div className="sec-info control-head">
        <div className="head">
          <h1 className="head-lg">Material Database</h1>
        </div>
        <div className="right">
          <Link to="/" title="Home" className="icon-btn alter">
            <i className="fa-solid fa-home" />
          </Link>
          <Link to="#" title="Help" className="icon-btn alter" onClick={() => { setOpenHelp(true) }}>
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>
      <div className="theme-card admin-h">
        <div className="body">
          <div className="md-container-2">
            {hasWritePermission && (
              <>
                <div className="row justify-between btn-list">
                  <div className="col-md-5">
                    <Link
                      to="/materials/new"
                      title="Add New Material"
                      className="theme-btn w-full brick fs-28"
                      onClick={()=>dispatch(materialAction(null))}
                    >
                      Add New Material
                    </Link>
                  </div>

                  {hasReadPermission && (
                    <div className="col-md-5">
                      <Link
                        to="/materials/find"
                        title="Find Material"
                        className="theme-btn w-full brick fs-28"
                        onClick={()=>dispatch(materialAction(null))}
                      >
                        Find Material
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}

            {hasReadPermission && (
              <>
                {!hasWritePermission && <div className="row btn-list justify-center">
                  <div className="col-md-5">
                    <Link
                      to="/materials/find"
                      title="Find Material"
                      className="theme-btn w-full brick fs-28"
                      onClick={()=>dispatch(materialAction(null))}
                    >
                      Find Material
                    </Link>
                  </div>
                </div>}

                <div className="row btn-list justify-between">
                  <div className="col-md-5">
                    <Link
                      to="/materials/list"
                      title="Material Screening"
                      className="theme-btn w-full brick fs-28"
                      onClick={()=>dispatch(materialAction(null))}
                    >
                      Material Screening
                    </Link>
                  </div>

                  <div className="col-md-5">
                    <Link
                      to="/materials/material-solubility"
                      title="Solubility"
                      className="theme-btn w-full brick fs-28"
                      onClick={()=>dispatch(materialAction(null))}
                    >
                      Solubility
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

    </Layout>
  );
};

// export default PermissionsWrapper(MaterialIndex);
export default MaterialIndex;
