import http from "./httpService";

const create = async (url: string, data: any) => {
  return await http.post(url, data);
};

const FileService = {
  create,
};

export default FileService;