const PERMISSION_MESSAGE = "It seems like you don't have the necessary permissions to access this page. \
    If you believe this is an error, please contact your administrator or support team for assistance. <br /> <br /> \
    If you think you should have access to this feature, please reach out to the system administrator to review and adjust your permissions. <br /> <br /> \
    <p>Thank you for your understanding.</p>";

const MULTIPLE_PERMISSIONS_MESSAGE = "Please clarify group permissions. You can not be part of both internal and external groups at the same time."

const NEW_MATERIAL_SUCCESS_MESSAGE = "Material data saved successfully.";
const UPDATE_MATERIAL_SUCCESS_MESSAGE = "Material data updated successfully.";
const NEW_EQUIPMENT_SUCCESS_MESSAGE = "Equipment data saved successfully.";
const UPDATE_EQUIPMENT_SUCCESS_MESSAGE = "Equipment data updated successfully.";
const NEW_EQUIPMENT_MODELS_SUCCESS_MESSAGE = "Equipment models data saved successfully.";
const UPDATE_EQUIPMENT_MODEL_SUCCESS_MESSAGE = "Equipment models data updated successfully.";
const DUPLICATE_FILE_ERROR_MESSAGE = "Please remove duplicate files.";
const MAX_FILESIZE_ERROR_MESSAGE = "Max file size exceed. Please try again with valid files.";
const ERROR_MESSAGE = "Oops! something went wrong.";

export {
    PERMISSION_MESSAGE,
    MULTIPLE_PERMISSIONS_MESSAGE,
    NEW_MATERIAL_SUCCESS_MESSAGE,
    UPDATE_MATERIAL_SUCCESS_MESSAGE,
    NEW_EQUIPMENT_SUCCESS_MESSAGE,
    UPDATE_EQUIPMENT_SUCCESS_MESSAGE,
    NEW_EQUIPMENT_MODELS_SUCCESS_MESSAGE,
    UPDATE_EQUIPMENT_MODEL_SUCCESS_MESSAGE,
    DUPLICATE_FILE_ERROR_MESSAGE,
    MAX_FILESIZE_ERROR_MESSAGE,
    ERROR_MESSAGE
}