import http from "./httpService";

async function simulate(data: any) {
  return await http.post("/simulations/crystallization", data);
}

const CrystallizationService = {
  simulate,
};

export default CrystallizationService;
