import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useOktaAuth } from "@okta/okta-react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// components
import Layout from "../../components/layout";
import CancelConfirmModal from "../../components/modals/CancelFormModal";
import MultiFileUpload from "../../components/common/MultiFileUpload";

// modals
import HelpModal from "../../components/modals/HelpModal";

// context
import { useUser } from "../../components/context/UserContext";

// redux actions
import { materialAction } from "../../redux/actions/materialActions";
import { alertCloseAction, alertOpenAction } from "../../redux/actions";

// props
import { RootState } from "../../redux/store";
import { FilesProps, MaterialData } from "../../types";
import { VendorProps } from "../../types/materialsTypes";

// helpers
import { NUMBER_INPUT_MAXLENGTH, UPLOAD_FILE_MAXLENGTH } from "../../utils";
import { MaterialHelp } from "../../utils/helpContent";
import { findFile, getUpdatedFiles, limitDigits, setTitleNumberInput, sortItemsByKey } from "../../utils/common";
import { attachmentsTypes, checkDuplicateFiles, vendorNames } from "../../utils/materialHelper";
import FileService from "../../services/fileService";
import MaterialService from "../../services/materialService";
import CustomLoader from "../../components/common/CustomLoader";
import { apiRoutes } from "../../utils/apiRoutes";

const schema = yup.object({
  material_name: yup.string().required("Please enter material name."),
  vendor_name: yup.string().required("Please enter vendor name."),
  phase: yup.string().when('page', {
    is: 'add',
    then: (schema) => schema.required("Please enter phase."),
    otherwise: (schema) => schema, // technically this otherwise isnt needed
  }),
  lot_number: yup.string().required("Please enter lot number."),
});

const MaterialNew = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : '';

  // user context
  const { user } = useUser();

  // Redux data
  const material = useSelector((state: RootState) => state?.material);

  //states
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [materialData, setMaterialData] = useState<any>();
  const [completed, setCompleted] = useState<any>();
  const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);
  const [selectedAttachmentType, setSelectedAttachmentType] = useState<string>(attachmentsTypes[0].value);
  const [attachments, setAttachments] = useState<FilesProps[] | []>([]);
  const [documents, setDocuments] = useState<FilesProps[] | []>([]);

  // form
  const initialData: MaterialData = {
    page: material?.item ? 'update' : 'add',
    material_name: materialData?.material_name ?? '',
    vendor_name: materialData?.vendor_name ?? '',
    phase: materialData?.phase ?? '',
    lot_number: materialData?.lot_number ?? '0000',
    abbreviation: materialData?.abbreviation ?? '',
    brand_name: materialData?.brand_name ?? '',
    synonyms: materialData?.synonyms ?? '',
    boiling_point: materialData?.boiling_point ?? '',
    cas_number: materialData?.cas_number ?? '',
    chemical_formula: materialData?.chemical_formula ?? '',
    incompatibility: materialData?.incompatibility ?? '',
    shelf_life: materialData?.shelf_life ?? '',
    melting_point: materialData?.melting_point ?? '',
    price: materialData?.price ?? '',
    molecular_weight: materialData?.molecular_weight ?? '',
    purity: materialData?.purity ?? '',
    smiles: materialData?.smiles ?? '',
    manufacturing_number: materialData?.manufacturing_number ?? '',
    project_name: materialData?.project_name ?? '',
    process_name: materialData?.process_name ?? '',
    chemical_safety: materialData?.chemical_safety ?? '',
    regulatory_filing_data: materialData?.regulatory_filing_data ?? '',
  };

  const { control, handleSubmit, formState: { errors }, formState, reset } = useForm<MaterialData>({
    resolver: yupResolver(schema), defaultValues: initialData
  });

  // To show warning when trying to exit form
  useEffect(() => {
    window.addEventListener('beforeunload', refreshData);
    return () => {
      window.removeEventListener('beforeunload', refreshData);
    };
  }, []);

  useEffect(() => {
    // set default title on number input fields.
    setTitleNumberInput();
    if (user) {
      const { userType, vendors } = user;
      if (userType.includes('external')) {
        // okta dashboard vendors for external users.
        setVendors(sortItemsByKey([
          { label: 'Generic', value: 'Generic' },
          ...vendors.map((vendor: any) => ({ label: vendor, value: vendor })),
        ], 'label'));
      }
    }
  }, [user]);

  const refreshData = (e: any) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    setMaterialData(material.item);
    material.item ? setCompleted(true) : '';
    reset(material.item);
    if (material?.item?.attachments?.length) {
      let newAttachments: any = [];
      let newDocuments: any = [];
      material?.item?.attachments?.forEach((attachment: any) => {
        let e: any = {};
        let d: any = {};
        if (attachment.category === 'Molecular Structure' ||
          attachment.category === 'USP Monograph' ||
          attachment.category === 'Chemical Safety' ||
          attachment.category === 'Purity & HPLC' ||
          attachment.category === 'SMILES' ||
          attachment.category === 'Others'
        ) {
          e.category = attachment.category;
          e.error = false;
          e.isUpload = true;
          e.file = {
            name: attachment.display_name,
            type: attachment.mime_type,
          };
          newAttachments.push(e);
        }
        if (attachment.category === 'Process Attachment') {
          d.category = attachment.category;
          d.error = false;
          d.isUpload = true;
          d.file = {
            name: attachment.display_name,
            type: attachment.mime_type,
          };
          newDocuments.push(d);
        }
      });
      setAttachments(newAttachments);
      setDocuments(newDocuments);
    }
  }, [material.item]);

  const uploadFile = async (file: any) => {
    let newAttachment = await findFile(attachments, file);
    if (!newAttachment) {
      newAttachment = await findFile(documents, file);
    }
    if (newAttachment) {
      const blob = new Blob([newAttachment?.file as any], { type: newAttachment?.file?.type });
      const uploadResponse = await fetch(file.signedUrl,
        {
          method: 'PUT',
          body: blob,
        })
      if (uploadResponse.ok) return true;
      return false;
    }
    return false;
  }

  const uploadFileAPI = async (data: any, attachmentList: any[], id?: string) => {
    let result: any = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    if (attachmentList.length) { // attachments list
      // file upload request
      const filesResponse = await FileService.create("/files/upload", {
        id: material?.item?.id ?? id,
        file_for: 'Material',
        files: attachmentList,
      });

      if (filesResponse?.status === 200) {
        const filesResult = filesResponse?.data;
        return Promise.all(filesResult.body.files.map(async (file: any) => await uploadFile(file)))
          .then(async (res) => {
            // update material object
            filesResult.body.files.map((file: any) => delete file.signedUrl);

            if (material?.item?.attachments?.length) {
              filesResult.body.files = [...material?.item?.attachments, ...filesResult.body.files];
            };

            filesResult.body.files = await getUpdatedFiles(filesResult?.body?.files ?? [], [...attachments, ...documents], !0);

            const materialResponse = await MaterialService.update({
              ...data,
              id: material?.item?.id ?? id,
              uid: `${auth?.claims?.uid}`,
              attachments: filesResult.body.files
            });

            if (materialResponse?.status === 200) {
              dispatch(materialAction(materialResponse?.data?.body));
              setCompleted(true);
              result.data = materialResponse?.data?.body;
              result.message = "Material updated successfully.";
              return result;
            }
            result.code = 400;
            result.status = "error";
            result.message = "Oops! something went wrong.";
            return result;
          })
          .catch(err => {
            result.code = 400;
            result.status = "error";
            result.message = "Oops! something went wrong.";
            return result;
          });
      }
      result.code = 400;
      result.status = "error";
      result.message = "Oops! something went wrong.";
      return result;
    }
    return result;
  }

  const apiRequest = async (data: any) => {
    let result: any = {
      code: 200,
      status: "success",
      message: "",
      data: {},
    }
    // attachments list
    let attachmentList = [...attachments, ...documents].filter((attachment: any) => !attachment.isUpload).map(
      (attachment) => ({ category: attachment?.category, key: attachment.file?.name, mimeType: attachment?.file?.type }));
    // if form already submitted : update data else add data
    if (material?.item) {
      if (attachmentList.length) {
        // upload files API
        return { ...result, ...await uploadFileAPI(data, attachmentList) };
      } else {
        const payload = {
          ...data,
          id: material?.item?.id,
          uid: `${auth?.claims?.uid}`,
          attachments: material?.item?.attachments?.reduce((e: any[], t: any) => {
            let newItem = attachments.find(
              (attachment: any) => (attachment.category === t.category && attachment?.file?.name === t.display_name));
            if (!newItem) {
              newItem = documents.find((document: any) => (document.category === t.category && document?.file?.name.toLocaleLowerCase().replaceAll(' ', '-') === t.display_name));
            }
            if (newItem) {
              e = [...e, t];
            }
            return e;
          }, []),
        };

        const materialResponse = await MaterialService.update(payload);
        if (materialResponse?.status === 200) {
          dispatch(materialAction(materialResponse?.data?.body));
          setCompleted(true);
          result.data = materialResponse?.data?.body;
          result.message = "Material updated successfully.";
          return result;
        }
        if (materialResponse?.status == 409) {
          result.code = materialResponse?.data?.code;
          result.status = "error";
          result.message = materialResponse?.data?.message;
          return result;
        }
        result.code = 400;
        result.status = "error";
        result.message = "Oops! something went wrong.";
        return result;
      }
    } else {
      const payload = {
        ...data,
        uid: `${auth?.claims?.uid}`,
      };

      const res = await MaterialService.create(apiRoutes.MATERIALS, payload);
      if (res?.status === 200) {
        if (res?.data?.code == 409) {
          result.code = res?.data?.code;
          result.status = "error";
          result.message = res?.data?.message;
          return result;
        } else if (res?.data?.code == 200) {
          dispatch(materialAction(res?.data?.body));
          if (attachmentList.length) {
            return {
              ...result,
              ...await uploadFileAPI(data, attachmentList, res?.data?.body?.id),
              ...{ message: "Material added successfully." },
            };
          }
          result.data = res?.data?.body;
          result.message = "Material added successfully.";
          return result;
        }
        result.code = 400;
        result.status = "error";
        result.message = "Oops! something went wrong.";
        return result;
      }
      result.code = 400;
      result.status = "error";
      result.message = "Oops! something went wrong.";
      return result;
    }
  }

  const validateForm = async () => {
    // find invalid files
    const invalidateFiles = [...attachments, ...documents].find((attachment: any) => attachment.error);
    if (invalidateFiles) { // Check invalid file
      dispatch(alertOpenAction('Max file size exceed. Please try again with valid files.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }

    // find duplicate files
    const isDuplicate = await (await checkDuplicateFiles([...attachments, ...documents]))?.map((item: any) => item.isDuplicate).includes(true);
    if (isDuplicate) {
      dispatch(alertOpenAction('Please remove duplicate files.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    return true;
  }

  // Form submit
  const onSubmit: SubmitHandler<any> = async (data: MaterialData) => {
    if (await validateForm()) {
      setLoading(true); // enable loading
      const apiResponse = await apiRequest(data);
      setLoading(false); // disable loading
      if (apiResponse.code === 200) {
        dispatch(alertOpenAction(apiResponse.message, 'success'));
      } else {
        dispatch(alertOpenAction(apiResponse.message, 'error'));
      }
      setTimeout(() => dispatch(alertCloseAction()));
    }
  };

  // Tabs 
  const phaseTab = () => {
    if (material.item) {
      if (materialData.phase == "Solid") {
        history.push("/materials/phase/solid")
      } else if (materialData.phase == "Gas") {
        history.push("/materials/phase/gas")
      } else if (materialData.phase == "Liquid") {
        history.push("/materials/phase/liquid")
      }
    } else {
      dispatch(alertOpenAction('Please add material data first.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  const solubilityTab = () => {
    if (material.item) {
      history.push("/materials/solubility")
    } else {
      dispatch(alertOpenAction('Please add material data first.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  const environmentalDataTab = () => {
    if (material.item) {
      history.push("/materials/environmental-data")
    } else {
      dispatch(alertOpenAction('Please add material data first.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  const spectralInfoTab = () => {
    if (material.item) {
      history.push("/materials/spectral-information")
    } else {
      dispatch(alertOpenAction('Please add material data first.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'processAttachments') {
      let displayNames = material?.item?.attachments ?? [];
      displayNames = displayNames?.map((attachment: any) => attachment.category == "Process Attachment" ? attachment.display_name : '');
      
      const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
        if (displayNames.includes(e?.target?.files?.[key].name)) {
          return true;
        }
      });
      if (isDuplicate) {
        dispatch(alertOpenAction('Same file already uploaded.', 'error'));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
      setDocuments([...documents, ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: 'Process Attachment',
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          }
        })]);
      return;
    }
    
    let displayNames = material?.item?.attachments ?? [];
      displayNames = displayNames?.map((attachment: any) => attachment.category == selectedAttachmentType ? attachment.display_name : '');      
      const isDuplicate = Object.keys(e?.target?.files ?? []).find((key: any) => {
        if (displayNames.includes(e?.target?.files?.[key].name)) {
          return true;
        }
      });
      if (isDuplicate) {
        dispatch(alertOpenAction('Same file already uploaded.', 'error'));
        setTimeout(() => dispatch(alertCloseAction()));
        return;
      }
    setAttachments([
      ...attachments,
      ...Object.keys(e?.target?.files ?? []).map(
        (key: any) => {
          const fileSize = e?.target?.files?.[key]?.size ?? 0;
          return {
            category: selectedAttachmentType,
            file: e?.target?.files?.[key],
            error: parseFloat((fileSize / (1024 * 1024)).toFixed(2)) > UPLOAD_FILE_MAXLENGTH ? true : false,
          }
        }),
    ]);
  }

  //Remove selected file
  const removeFile = (index: number, name: string) => {
    if (name === 'processAttachments') {
      setDocuments(documents.filter((document, i) => i !== index));
      return;
    }
    setAttachments(attachments.filter((attachment, i) => i !== index));
  }

  // Cancel button functions
  const saveMaterial = async () => {
    const { vendor_name, material_name, lot_number } = control._formValues;
    if ((!vendor_name?.length || !material_name?.length || !lot_number?.length)) {
      setOpen(false);
      dispatch(alertOpenAction('Please fill all required fields first.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
      return;
    }
    if (JSON.stringify(initialData) !== JSON.stringify(control._formValues)) {
      setOpen(false);
      if (await validateForm()) {
        setLoading(true); // enable loading
        const apiResponse = await apiRequest({ ...control._formValues });
        setLoading(false); // disable loading
        if (apiResponse.code === 200) {
          dispatch(alertOpenAction(apiResponse.message, 'success'));
          history.push('/materials');
        } else {
          dispatch(alertOpenAction(apiResponse.message, 'error'));
        }
        setTimeout(() => dispatch(alertCloseAction()));
      }
    } else {
      setOpen(false);
      dispatch(alertOpenAction('No data updated to save.', 'error'));
      setTimeout(() => dispatch(alertCloseAction()));
    }
  }

  const dontSave = () => {
    history.push('/materials');
  }

  const hasWritePermission = user?.permissions?.updated?.material?.hasReadAndWrite??false;

  return (
    <Layout title="Material New">
      <CancelConfirmModal
        open={open}
        setOpen={setOpen}
        saveMaterial={saveMaterial}
        dontSave={dontSave}
      />
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title={MaterialHelp.title}
        content={MaterialHelp.content} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="sec-info control-head">
          <div className="back"></div>
          <div className="head">
            <h1 className="head-lg">Add New Material</h1>
          </div>
          <div className="right">
            {hasWritePermission && (
              <button type="submit" title="Save" disabled={loading ? true : false} className={`icon-btn ${loading ? "disabled  " : ""}`}>
                <i className="fa-regular fa-floppy-disk" />
              </button>
            )}
            <Link className="icon-btn alter" title="Close" to="#" onClick={() => {
              if (formState.isDirty || Object.keys(formState.dirtyFields)?.length ||
                (!material?.item && [...attachments, ...documents].length) ||
                (Array.isArray(material?.item?.attachments) && (material?.item?.attachments?.length !== [...attachments, ...documents]?.length))
              ) {
                setOpen(true);
                return;
              }
              history.push(`/materials`);
            }}>
              <i className="fa-solid fa-xmark" />
            </Link>
            <Link to="#" className="icon-btn alter" title="Help" onClick={() => { setOpenHelp(true) }}>
              <i className="fa-solid fa-question" />
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="theme-card">
              <div className="head">
                <div className="admin-tabs">
                  <ul className="inner">
                    <li>
                      <Link to="#" className={`tab-link ${completed ? "done" : ""} active  `}>
                        Material
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className={`tab-link ${materialData && (materialData?.gas || materialData?.solid || materialData?.liquid) ? 'done' : ''}`}
                        onClick={() => phaseTab()}>
                        Phase
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className={`tab-link ${materialData && materialData?.solubility ? 'done' : ''}`}
                        onClick={() => solubilityTab()}>
                        Solubility
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className={`tab-link ${materialData && materialData?.spectralInfo ? 'done' : ''}`}
                        onClick={() => spectralInfoTab()}>
                        Spectral Information
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className={`tab-link ${materialData && materialData?.environment ? 'done' : ''}`}
                        onClick={() => environmentalDataTab()}>
                        Environmental Data
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="body">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap required" htmlFor="materialName">
                            <span className="form-label">Material Name</span>
                            <Controller
                              name="material_name"
                              control={control}
                              defaultValue={control._formValues?.material_name ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                            {errors.material_name && <p className="error">{errors.material_name?.message}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap required" htmlFor="vendor">
                            <span className="form-label">Vendor</span>
                            <div className="input-wrap select-outer">
                              <Controller
                                name="vendor_name"
                                control={control}
                                defaultValue={control._formValues?.vendor_name ?? ""}
                                render={({ field }) => (
                                  <select className="theme-ip" {...field}>
                                    <option value="" disabled selected>Select</option>
                                    {vendors?.map((vendor: VendorProps, index: number) => (
                                      <option key={index} value={vendor?.value}>
                                        {vendor.label}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              />
                              {errors.vendor_name && <p className="error">{errors.vendor_name?.message}</p>}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap required" htmlFor="phase">
                            <span className="form-label">Phase</span>
                            <div className="input-wrap select-outer">
                              <Controller
                                name="phase"
                                control={control}
                                defaultValue={control._formValues?.phase ?? ""}
                                render={({ field }) => (
                                  <select className="theme-ip" {...field} disabled={materialData ? true : false}>
                                    <option value="" disabled>Select</option>
                                    <option value="Gas">Gas</option>
                                    <option value="Liquid">Liquid</option>
                                    <option value="Solid">Solid</option>
                                  </select>
                                )}
                              />
                              {errors.phase && <p className="error">{errors.phase?.message?.toString()}</p>}
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="abbreviation" title="Abbreviation">
        <span className="form-label">Abbreviation</span>
                            <Controller
                              name="abbreviation"
                              control={control}
                              defaultValue={control._formValues?.abbreviation ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="brandName" title="Brand Name">
        <span className="form-label">Brand Name</span>
                            <Controller
                              name="brand_name"
                              control={control}
                              defaultValue={control._formValues?.brand_name ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="synonyms" title="Synonyms">
        <span className="form-label">Synonyms</span>
                            <Controller
                              name="synonyms"
                              control={control}
                              defaultValue={control._formValues?.synonyms ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="boilingPoint" title="Boiling Point (C)">
        <span className="form-label">Boiling Point (C)</span>
                            <Controller
                              name="boiling_point"
                              control={control}
                              render={({ field }) => <input type="number"
                                step="any"
                                defaultValue={control._formValues?.boiling_point ?? ""}
                                onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)}
                                className="theme-ip" {...field} />}
                            />
                            {errors.boiling_point && <p>{errors.boiling_point?.message?.toString()}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="cas_number" title="CAS Number">
        <span className="form-label">CAS Number</span>
                            <Controller
                              name="cas_number"
                              control={control}
                              defaultValue={control._formValues?.cas_number ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="chemical_formula" title="Chemical Formula">
        <span className="form-label">Chemical Formula</span>
                            <Controller
                              name="chemical_formula"
                              control={control}
                              defaultValue={control._formValues?.chemical_formula ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="incompatibility" title="Incompatibility">
        <span className="form-label">Incompatibility</span>
                            <Controller
                              name="incompatibility"
                              control={control}
                              defaultValue={control._formValues?.incompatibility ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="shelf_life" title="Decomposition/Shelf-life (Month)">
        <span className="form-label">Decomposition/Shelf-life (Month)</span>
                            <Controller
                              name="shelf_life"
                              control={control}
                              defaultValue={control._formValues?.shelf_life ?? ""}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="chemical_safety" title="Chemical Safety">
        <span className="form-label">Chemical Safety</span>
                            <div className="input-wrap select-outer">
                              <Controller
                                name="chemical_safety"
                                control={control}
                                defaultValue={control._formValues?.chemical_safety ?? ""}
                                render={({ field }) => (
                                  <select className="theme-ip" {...field}>
                                    <option value="">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                  </select>
                                )}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="melting_point" title="Melting Point (C)">
        <span className="form-label">Melting Point (C)</span>
                            <Controller
                              name="melting_point"
                              control={control}
                              defaultValue={control._formValues?.melting_point ?? ""}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                            {errors.melting_point && <p className="error">{errors.melting_point?.message?.toString()}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="price" title="Price ($/g)">
        <span className="form-label">Price ($/g)</span>
                            <Controller
                              name="price"
                              control={control}
                              defaultValue={control._formValues?.price ?? ""}
                              render={({ field }) => <input step="any" type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                            {errors.price && <p className="error">{errors.price?.message?.toString()}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="molecular_weight" title="Molecular Weight">
        <span className="form-label">Molecular Weight</span>
                            <Controller
                              name="molecular_weight"
                              control={control}
                              defaultValue={control._formValues?.molecular_weight ?? ""}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                            {errors.molecular_weight && <p>{errors.molecular_weight?.message?.toString()}</p>}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="purity">
                            <span className="form-label">
                              Purity and HPLC method
                            </span>
                            <Controller
                              name="purity"
                              control={control}
                              defaultValue={control._formValues?.purity ?? ""}
                              render={({ field }) => <input type="number" onInput={(e: any) => limitDigits(e, NUMBER_INPUT_MAXLENGTH)} className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="smiles" title="SMILES">
        <span className="form-label">SMILES</span>
                            <Controller
                              name="smiles"
                              control={control}
                              defaultValue={control._formValues?.smiles ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <MultiFileUpload
                      title="Upload File"
                      name="uploadFiles"
                      hasAttachmentTypes={true}
                      attachmentsTypes={attachmentsTypes}
                      setSelectedAttachmentType={setSelectedAttachmentType}
                      handleFileChange={handleFileChange}
                      removeFile={removeFile}
                      attachments={attachments}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-9">
                    <div className="divider"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap required" htmlFor="lot_number" title={"Lot Number"}>
                            <span className="form-label">Lot Number</span>
                            <Controller
                              name="lot_number"
                              control={control}
                              defaultValue={control._formValues?.lot_number ?? "0000"}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                          {errors.lot_number && <p className="error">{errors.lot_number?.message?.toString()}</p>}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="manufacturing_number" title="Manufacturing Number">
        <span className="form-label">Manufacturing Number</span>
                            <Controller
                              name="manufacturing_number"
                              control={control}
                              defaultValue={control._formValues?.manufacturing_number ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="project_name" title="Project Name">
        <span className="form-label">Project Name</span>
                            <Controller
                              name="project_name"
                              control={control}
                              defaultValue={control._formValues?.project_name ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label
                            className="ip-wrap"
                            htmlFor="regulatory_filing_data"
                          >
                            <span className="form-label">
                              Regulatory Filing Data
                            </span>
                            <div className="input-wrap select-outer">
                              <Controller
                                name="regulatory_filing_data"
                                control={control}
                                defaultValue={control._formValues?.regulatory_filing_data ?? ""}
                                render={({ field }) => (
                                  <select className="theme-ip" {...field}>
                                    <option value="">Select</option>
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                  </select>
                                )}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <label className="ip-wrap" htmlFor="process_name" title="Process Name">
        <span className="form-label">Process Name</span>
                            <Controller
                              name="process_name"
                              control={control}
                              defaultValue={control._formValues?.process_name ?? ""}
                              render={({ field }) => <input className="theme-ip" {...field} />}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <MultiFileUpload
                      title="Process Attachment"
                      name="processAttachments"
                      handleFileChange={handleFileChange}
                      removeFile={removeFile}
                      attachments={documents}
                    />
                  </div>
                </div>
              </div>
              {loading ? <CustomLoader /> : ''}
            </div>
          </div>
        </div>
      </form>
    </Layout >
  );
};

export default MaterialNew;
