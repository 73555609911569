// Equipment Types
const EquipmentTypes: any = [
    { label: 'Distillation', value: 'distillation' },
    { label: 'Evaporator', value: 'evaporator' },
    { label: 'Filtration', value: 'filtration' },
    { label: 'Mixer', value: 'mixer' },
    { label: 'Reactor and Crystallizer', value: 'reactor' },
    { label: 'Vessel and Tanks', value: 'vessel' },
];

// Equipment Owners
const EquipmentOwners = [
    { label: 'APC', value: 'APC' },
    { label: 'Cambrex', value: 'Cambrex' },
    { label: 'Carbogen', value: 'Carbogen' },
    { label: 'CDMO1', value: 'CDMO1' },
    { label: 'JetPharma', value: 'JetPharma' },
    { label: 'Nalas', value: 'Nalas' },
    { label: 'Snapdraggon', value: 'Snapdraggon' },
    { label: 'Takeda Cambridge', value: 'Takeda Cambridge' },
    { label: 'Takeda Japan', value: 'Takeda Japan' },
    { label: 'Wuxi', value: 'Wuxi' },
];

// Vessel
const vesselAuxiliaryUnits = [
    {
        value: "sparger", label: "Sparger",
    },
    {
        value: "mixer", label: "Mixer",
    },
    {
        value: "condenser", label: "Condenser",
    },
    {
        value: "psv", label: "PSV",
    },
];

// common in vessel/reactor
const vesselReactorTextFields = [
    {
        value: "total_volume", label: "Total Volume (L)", type: "number", title: 'Nominal Capacity (L)'
    },
    {
        value: "working_volume", label: "Working Volume (L)", type: "number", title: 'Normal working capacity (L)'
    },
    {
        value: "height", label: "Height (m)", type: "number", title: 'Equipment Height, end-to-end (m)'
    },
    {
        value: "id", label: "ID (m)", type: "number"
    },
    {
        value: "od", label: "OD (m)", type: "number"
    },
    {
        value: "wall_thickness", label: "Wall Thickness (mm)", type: "number"
    },
    {
        value: "number_of_ports", label: "Number of Ports", type: "number"
    },
    {
        value: "rated_pressure", label: "Rated Pressure (atm)", type: "number"
    }
];

const yesNoOptions = [
    {
        value: "yes", label: "Yes",
    },
    {
        value: "no", label: "No",
    },
];

const dischargeLocationOptions = [
    {
        value: "side", label: "Side",
    },
    {
        value: "bottom", label: "Bottom",
    },
];

const heatExchangerOptions = [
    {
        value: "jacket", label: "Jacket",
    },
    {
        value: "internal", label: "Internal",
    },
];

const bottomOptions = [
    {
        value: "flat", label: "Flat",
    },
    {
        value: "round", label: "Round",
    },
];

const vesselSelectFields = [
    {
        value: "discharge_location", label: "Discharge Location", options: dischargeLocationOptions,
    },
    {
        value: "insulated", label: "Insulated", options: yesNoOptions,
    },
    {
        value: "heating_cooling", label: "Heating/Cooling", options: yesNoOptions,
    },
    {
        value: "heat_exchanger", label: "Heat Exchanger", options: heatExchangerOptions,
    },
    {
        value: "bottom", label: "Bottom", options: bottomOptions,
    },
    {
        value: "auxiliary_units", label: "Auxiliary Units", options: vesselAuxiliaryUnits,
    },
    {
        value: "cip", label: "CIP", options: yesNoOptions, title: 'Cleaning in Place'
    },
];

// General Form Text Fields
const equipmentTextFields = [
    // {
    //     value: "tag_number", label: "Equipment Tag Number", required: true
    // },
    {
        value: "location", label: "Location", title: "Physical Location of the Asset"
    },
    {
        value: "brand", label: "Brand",
    },
    {
        value: "manufacturer_model_number", label: "Manufacturer Model Number",
    },
    {
        value: "material_of_construction", label: "Material of Construction (wet)",
        title: `Wetted Material, e.g. a Glass Lined Stainless Steel Reactor is "GLASS"`
    },
];

const generalApplicationOptions = [
    {
        value: "lab", label: "Lab",
    },
    {
        value: "manufacturing", label: "Manufacturing",
    },
    {
        value: "pilot", label: "Pilot",
    },
];

const generalEquipmentTextFieldsFind = [
    {
        value: "location", label: "Location",
    },
    {
        value: "brand", label: "Brand",
    },
    {
        value: "manufacturer_model_number", label: "Manufacturer Model Number",
    },
    {
        value: "material_of_construction", label: "Material of Construction (wet)",
    },
    {
        value: "application_and_scale", label: "Application and Scale",
    },
];

// Reactor
const reactorAuxiliaryUnits = [
    {
        value: "sparger", label: "Sparger",
    },
    {
        value: "mixer", label: "Mixer",
    },
    {
        value: "condenser", label: "Condenser",
    },
    {
        value: "light", label: "Light",
    },
];

const reactorSelectFields = [
    {
        value: "discharge_location", label: "Discharge Location", options: dischargeLocationOptions,
    },
    {
        value: "insulated", label: "Insulated", options: yesNoOptions,
    },
    {
        value: "heating_cooling", label: "Heating/Cooling", options: yesNoOptions,
    },
    {
        value: "heat_exchanger", label: "Heat Exchanger", options: heatExchangerOptions,
    },
    {
        value: "bottom", label: "Bottom", options: bottomOptions,
    },
    {
        value: "auxiliary_units", label: "Auxiliary Units", options: reactorAuxiliaryUnits,
    },
    {
        value: "cip", label: "CIP", options: yesNoOptions, title: 'Cleaning in Place'
    },
];

// Distillation Form Text Fields
const distillationTextFields = [
    {
        value: "total_volume", label: "Total Volume (L)", type: "number", title:'Nominal Capacity (L)'
    },
    {
        value: "id", label: "ID (mm)", type: "number"
    },
    {
        value: "height", label: "Height (m)", type: "number", title:'Equipment Height, end-to-end (m)'
    },
    {
        value: "number_of_ports", label: "Number of Ports", type: "number"
    },
];

const distillationType = [
    {
        value: "packed", label: "Packed",
    },
    {
        value: "rotaVap", label: "RotaVap",
    },
    {
        value: "tray", label: "Tray",
    },
    {
        value: "azeotrope", label: "Azeotrope",
    },
];

const distillationHeatExchanger = [
    {
        value: "reflux", label: "Reflux",
    },
    {
        value: "reboiler", label: "Reboiler",
    },
    {
        value: "jacket", label: "Jacket",
    },
    {
        value: "internal_coil", label: "Internal Coil",
    },
];

const distillationPackingType = [
    {
        value: "none", label: "None",
    },
    {
        value: "random", label: "Random",
    },
    {
        value: "structured", label: "Structured",
    },
];

const distillationPackingMaterial = [
    {
        value: "metal", label: "Metal",
    },
    {
        value: "ceramic", label: "Ceramic",
    },
    {
        value: "plastic", label: "Plastic",
    },
];

const distillationTrayType = [
    {
        value: "sieve", label: "Sieve",
    },
    {
        value: "valve", label: "Valve",
    },
];

const distillationAuxiliaryUnits = [
    {
        value: "sparger", label: "Sparger",
    },
    {
        value: "demister", label: "Demister",
    },
    {
        value: "top_distributor", label: "Top Distributor",
    },
    {
        value: "psv", label: "PSV",
    },
];

// Filtration Form Text Fields
const filtrationTextFields = [
    {
        value: "mesh_size", label: "Mesh size (mic)", type: "number"
    },
    {
        value: "id", label: "ID (mm)", type: "number"
    },
    {
        value: "liquid_volume_capacity", label: "Liquid Volume Capacity (L)", type: "number", title:'Liquid Volume Capacity (L)'
    },
    {
        value: "solid_capacity", label: "Solid Capacity (kg)", type: "number", title:'Nominal solid capacity (kg)'
    },
    {
        value: "max_filter_cake_thickness", label: "Max Filter Cake Thickness (mm)", type: "number"
    },
];

const filtrationType = [
    {
        value: "nutche", label: "Nutche",
    },
    {
        value: "candle", label: "Candle",
    },
    {
        value: "press", label: "Press",
    },
    {
        value: "awl", label: "AWL",
    },
];

const filtrationFilterMaterial = [
    {
        value: "silica", label: "Silica",
    },
    {
        value: "paper", label: "Paper",
    },
    {
        value: "metal", label: "Metal",
    },
];

const filtrationWorkingPressure = [
    {
        value: "positive", label: "Positive",
    },
    {
        value: "vacuum", label: "Vacuum",
    },
];

// Mixer Form Text Fields
const mixerTextFields = [
    {
        value: "number_of_mixing_element", label: "Number of Mixing Element", type: "number"
    },
    {
        value: "number_of_blades", label: "Number of Blades", type: "number"
    },
    {
        value: "mixer_angle", label: "Mixer Angle (deg)", type: "number"
    },
    {
        value: "blade_angle", label: "Blade Angle (deg)", type: "number"
    },
    {
        value: "blade_vertical_height", label: "Blade Vertical Height (mm)", type: "number"
    },
    {
        value: "mixer_diameter", label: "Mixer Diameter (mm)", type: "number"
    },
    {
        value: "blade_thickness", label: "Blade Thickness (mm)", type: "number"
    },
    {
        value: "shaft_length", label: "Shaft Length (mm)", type: "number"
    },
    {
        value: "shaft_od", label: "Shaft OD (mm)", type: "number"
    },
    {
        value: "motor_power", label: "Motor Power (w)", type: "number"
    },
];

const mixerType = [
    {
        value: "marine", label: "Marine",
    },
    {
        value: "pitched", label: "Pitched",
    },
    {
        value: "anchor", label: "Anchor",
    },
    {
        value: "hydrofoil", label: "Hydrofoil",
    },
    {
        value: "rushton", label: "Rushton",
    },
    {
        value: "cbr", label: "CBR",
    },
    {
        value: "pbt", label: "PBT",
    },
];

const mixerShaftConnector = [
    {
        value: "coupling", label: "Coupling",
    },
    {
        value: "direct", label: "Direct",
    },
];

// Evaporator Form Text Fields
const evaporatorTextFields = [
    {
        value: "total_volume", label: "Total Volume (L)", type: "number", title:'Nominal Capacity (L)'
    },
    {
        value: "working_volume", label: "Working Volume (L)", type: "number", title:'Normal working capacity (L)'
    },
    {
        value: "pressure", label: "Pressure (atm)", type: "number", title:'Rated pressure (atm)'
    },
    {
        value: "surface_area", label: "Surface Area (m2)", type: "number", title:'Nominal wet surface area (m2)'
    },
    {
        value: "max_viscosity", label: "Max viscosity (cP)", type: "number"
    },
];

const evaporatorType = [
    {
        value: "falling_film", label: "Falling Film",
    },
    {
        value: "rotaVap", label: "RotaVap",
    },
    {
        value: "vacuum", label: "Vacuum",
    },
];

const evaporatorFeedingMethod = [
    {
        value: "distributor", label: "Distributor",
    },
    {
        value: "batch", label: "Batch",
    },
];

const evaporatorHeating = [
    {
        value: "reboiler", label: "Reboiler",
    },
    {
        value: "jacket", label: "Jacket",
    },
];

// Type Properties Screening Filters
const typeProperties: any = {
    vessel: [
        {
            value: "total_volume", label: "Total Volume (L)", type: 'input',
        },
        {
            value: "id", label: "ID (m)", type: 'input',
        },
        {
            value: "rated_pressure", label: "Rated Pressure (atm)", type: 'input',
        }
    ],
    reactor: [
        {
            value: "total_volume", label: "Total Volume (L)", type: 'input',
        },
        {
            value: "id", label: "ID (m)", type: 'input',
        },
        {
            value: "rated_pressure", label: "Rated Pressure (atm)", type: 'input',
        },
        {
            value: "heat_exchanger", label: "Heat Exchanger", type: 'select', options: heatExchangerOptions,
        },
    ],
    distillation: [
        {
            value: "type", label: "Type", type: 'select', options: distillationType
        },
        {
            value: "total_volume", label: "Total Volume (L)", type: 'input',
        },
        {
            value: "id", label: "ID (m)", type: 'input',
        },
        {
            value: "packing_type", label: "Packing Type", type: 'select', options: distillationPackingType
        },
        {
            value: "tray_type", label: "Tray Type", type: 'select', options: distillationTrayType,
        },
    ],
    filtration: [
        {
            value: "type", label: "Type", type: 'select', options: filtrationType
        },
        {
            value: "mesh_size", label: "Mesh size (mic)", type: 'input',
        },
        {
            value: "id", label: "ID (m)", type: 'input',
        },
        {
            value: "liquid_volume_capacity", label: "Liquid Volume Capacity (L)", type: 'input',
        },
        {
            value: "solid_capacity", label: "Solid Capacity (kg)", type: 'input',
        },
    ],
    evaporator: [
        {
            value: "type", label: "Type", type: 'select', options: evaporatorType
        },
        {
            value: "total_volume", label: "Total Volume (L)", type: 'input',
        },
        {
            value: "pressure", label: "Pressure (atm)", type: 'input',
        },
        {
            value: "solvent_recovery", label: "Solvent recovery", type: 'select', options: yesNoOptions,
        },
    ],
    mixer: [
        {
            value: "type", label: "Type", type: 'select', options: mixerType
        },
        {
            value: "mixer_diameter", label: "Mixer Diameter (mm)", type: 'input',
        },
        {
            value: "shaft_length", label: "Shaft Length (mm)", type: 'input',
        },
    ],
}

export { 
    EquipmentTypes,
    EquipmentOwners,
    vesselAuxiliaryUnits,
    vesselReactorTextFields,
    yesNoOptions,
    dischargeLocationOptions,
    heatExchangerOptions,
    bottomOptions,
    vesselSelectFields,
    equipmentTextFields,
    generalApplicationOptions,
    generalEquipmentTextFieldsFind,
    reactorAuxiliaryUnits,
    reactorSelectFields,
    distillationTextFields,
    distillationType,
    distillationHeatExchanger,
    distillationPackingType,
    distillationPackingMaterial,
    distillationTrayType,
    distillationAuxiliaryUnits,
    filtrationTextFields,
    filtrationType,
    filtrationFilterMaterial,
    filtrationWorkingPressure,
    mixerTextFields,
    mixerType,
    mixerShaftConnector,
    evaporatorTextFields,
    evaporatorType,
    evaporatorFeedingMethod,
    evaporatorHeating,
    typeProperties,
}
