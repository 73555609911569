import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import logo from '../../assets/logo_takeda.png';

const Sidebar = ({ setCorsErrorModalOpen }: any) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [activeTab, setActiveTab] = useState<string>();

  useEffect(() => {
    if (/material/g.test(window.location.href)) {
      setActiveTab("material");
    } else if (/equipments/g.test(window.location.href)) {
      setActiveTab("equipments");
    } else if (/internal-processes/g.test(window.location.href)) {
      setActiveTab("internal-processes");
    } else {
      setActiveTab("dashboard");
    }
  }, []);

  // Note: Can't distinguish CORS error from other network errors
  const isCorsError = (err: any) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');

  // const login = async () => history.push('/login');

  const logout = async () => {
    const basename = window.location.origin; // + history.createHref({ pathname: '/' });
    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  return (
    <aside className="sidebar">
      <header>
        <div className="side-head">
          <span className="img">
            <img
              src={logo}
              alt="Logo"
              className="img-fluid fav"
              height="860"
              width="2560"
            />
            {/* <img
              src={}
              alt="Logo"
              className="img-fluid"
              height="16"
              width="133"
            /> */}
          </span>
          <div className="text logo-text">
            <button
              type="button"
              title="Open"
              className="sidebar-toggler"
              onClick={() => {
                const body = document.querySelector("#root");
                if (body?.classList.contains("sidebar-open")) {
                  body?.classList.remove("sidebar-open");
                } else {
                  body?.classList.add("sidebar-open");
                }
              }}
            >
              <span className="bar" />
              <span className="bar m" />
              <span className="bar" />
            </button>
          </div>
        </div>
      </header>

      <div className="menu-bar">
        <div className="menu">
          <ul className="menu-links">
            <li className={`item ${activeTab == "dashboard" ? 'active' : ''}`} >
              <Link to="/" className="link">
                <i className="fa-solid fa-chart-simple" />
                <span className="text">Dashboard</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "material" ? 'active' : ''}`}>
              <Link to="/materials" className="link" title="Materials">
                <i className="fa-solid fa-boxes-stacked" />
                <span className="text">Materials</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "equipments" ? 'active' : ''}`}>
              <Link to="/equipments" className="link" title="Equipments">
                <i className="fa-solid fa-toolbox" />
                <span className="text">Equipments</span>
              </Link>
            </li>
            <li className={`item ${activeTab === "internal-processes" ? "active" : ""}`}>
              <Link to="/internal-processes" className="link" title="Equipments">
                <i className="fa-solid fa-toolbox" />
                <span className="text">Internal processes</span>
              </Link>
            </li>
            <li className={`item ${activeTab == "External companies" ? 'active' : ''}`}>
              <Link to="/equipments" className="link" title="Equipments">
                <i className="fa-solid fa-toolbox" />
                <span className="text">External companies</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="bottom-content">
          <ul className="menu-links">
            {authState.isAuthenticated && (
              <li className="item">
                <button className="link" title="Logout" id="logout-button" onClick={logout}>
                  <i className="fa-solid fa-gear" />
                  <span className="text">Logout</span>
                </button>
              </li>
            )}
            {!authState.isPending && !authState.isAuthenticated && (
              <li className="item">
                <Link to="/login" className="link" title="Login">
                  <i className="fa-solid fa-gear" />
                  <span className="text">Login</span>
                </Link>
              </li>
            )}
            {/* <li className="item">
              <Link to="/" className="link" title="Logout">
                <i className="fa-solid fa-gear" />
                <span className="text">Logout</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
